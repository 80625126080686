import React, { useEffect, useState } from 'react';
import { CiEdit, CiSquareInfo } from 'react-icons/ci';
import { Button, Drawer, Table, message, Input, Popconfirm } from 'antd';
import './infocustomer.css'
import TambahCustomer from './CustomerBaru';
import EditCustomer from './EditPelanggan';
import CekDataCustomer from './checkDataCust';
import { SearchOutlined } from '@ant-design/icons';
import CreateAxiosInstance from '../../../Authentication/contexts/axiosConfig';
const DataCustomer = () =>
{
    const [ data, setData ] = useState( [] );
    const axiosConfig = CreateAxiosInstance();
    const [ loading, setLoading ] = useState( false );
    const [ drawerTambahData, setDrawerTambahData ] = useState( false );
    const [ drawerEditData, setDrawerEditData ] = useState( false );
    const [ editRecordId, setEditRecordId ] = useState( null );
    const [ drawerCheckData, setDrawerCheckData ] = useState( false );
    const [ filterBlok, setFilterBlok ] = useState( [] );
    const [ filterStatus, setFilterStatus ] = useState( [] );
    const [ filterLokasi, setFilterLokasi ] = useState( [] );
    const [ filterTypeProperty, setFilterTypeProperty ] = useState( [] );
    const [ searchedText, setSearchedText ] = useState( '' );
    const fetchData = async () =>
    {
        setLoading( true );
        try
        {
            const response = await axiosConfig.get( `${ process.env.REACT_APP_LOCAL_API }/customer/data` );
            const updatedData = response.data.map( customer => ( {
                ...customer,
                namaCustomer: customer.namaCustomer.length > 50 ?
                    `${ customer.namaCustomer.slice( 0, 50 ) }...` : customer.namaCustomer,
                alamatCustomer: customer.alamatCustomer.length > 50 ?
                    `${ customer.alamatCustomer.slice( 0, 50 ) }...` : customer.alamatCustomer,
            } ) );
            setData( updatedData );
            setFilterBlok( getUniqueBlok( updatedData ) );
            setFilterLokasi( getUniqueLokasi( updatedData ) );
            setFilterTypeProperty( getUniqueProperti( updatedData ) );
            setFilterStatus( getUniqueStatus( updatedData ) );
        } catch ( error )
        {
            message.error( 'Gagal mengambil data dari server' );
        } finally
        {
            setLoading( false )
        }
    };
    const getUniqueBlok = data =>
    {
        const uniqueTypes = [ ...new Set( data.map( item => item.blokKavling ) ) ];
        return uniqueTypes.map( type => ( { text: type, value: type } ) );
    };
    const getUniqueProperti = data =>
    {
        const uniqueTypes = [ ...new Set( data.map( item => item.jenisProperty ) ) ];
        return uniqueTypes.map( type => ( { text: type, value: type } ) );
    };
    const getUniqueLokasi = data =>
    {
        const uniqueTypes = [ ...new Set( data.map( item => item.lokasiKavling ) ) ];
        return uniqueTypes.map( type => ( { text: type, value: type } ) );
    };
    const getUniqueStatus = data =>
    {
        const uniqueTypes = [ ...new Set( data.map( item => item.statusPembelian ) ) ];
        return uniqueTypes.map( type => ( { text: type, value: type } ) );
    };
    useEffect( () =>
    {
        fetchData();
    }, [] );
    const handleSearch = e =>
    {
        const value = e.target.value.toLowerCase();
        setSearchedText( value );
    };

    const filteredData = data.filter( item =>
        item.namaCustomer.toLowerCase().includes( searchedText ) ||
        item.alamatCustomer.toLowerCase().includes( searchedText )
    );

    const columns = [
        {
            title: 'Aksi',
            key: 'action',
            width: '10%',
            align: 'center',
            render: ( text, record ) => (
                <div style={ { display: 'flex', gap: 5, justifyContent: 'center' } }>
                    <Button
                        style={ { margin: '0' } }
                        type='default'
                        onClick={ () => handleEditData( record._id ) }
                        icon={ <CiEdit /> }
                    />
                    <Button
                        style={ { margin: '0' } }
                        type='default'
                        onClick={ () => handleCheckData( record._id ) }
                        icon={ <CiSquareInfo /> }
                    />
                </div>
            ),
        },
        {
            title: <p style={ { textAlign: 'center' } }>Nama Pelanggan</p>,
            dataIndex: 'namaCustomer',
            align: 'left',
            width: '20vw',
            sorter: ( a, b ) => a.namaCustomer.localeCompare( b.namaCustomer ),
        },
        {
            title: <p style={ { textAlign: 'center' } }>Alamat</p>,
            dataIndex: 'alamatCustomer',
            width: '20vw',
            align: 'left',
        },
        {
            title: 'Kavling',
            width: 'auto',
            align: 'center',
            children: [
                {
                    title: 'Blok',
                    dataIndex: 'blokKavling',
                    width: 'auto',
                    align: 'center',
                    filters: filterBlok,
                    onFilter: ( value, record ) => record.blokKavling.includes( value )
                },
                {
                    title: 'No',
                    dataIndex: 'noKavling',
                    width: 'auto',
                    align: 'center',
                    sorter: ( a, b ) => a.noKavling - b.noKavling,
                },
            ]
        },
        {
            title: 'Lokasi',
            dataIndex: 'lokasiKavling',
            width: 'auto',
            align: 'center',
            filters: filterLokasi,
            onFilter: ( value, record ) => record.lokasiKavling.includes( value )
        },
        {
            title: 'Properti',
            dataIndex: 'jenisProperty',
            align: 'center',
            width: 'auto',
            filters: filterTypeProperty,
            onFilter: ( value, record ) => record.jenisProperty.includes( value )
        },
        {
            title: 'Status',
            dataIndex: 'statusPembelian',
            align: 'center',
            width: 'auto',
            filters: filterStatus,
            onFilter: ( value, record ) => record.statusPembelian.includes( value ),

        },
    ];
    const handleCheckData = recordId =>
    {
        setEditRecordId( recordId );
        setDrawerCheckData( true );
    };
    const handleCloseCheckData = () =>
    {
        setDrawerCheckData( false );
        setEditRecordId( null );
    };
    const handleEditData = recordId =>
    {
        setEditRecordId( recordId );
        setDrawerEditData( true );
    };
    const handleCloseEditDrawer = () =>
    {
        setDrawerEditData( false );
        setEditRecordId( null );
    };
    const handleTambahData = () =>
    {
        setDrawerTambahData( true );
    };
    const handleCloseTambahLahan = () =>
    {
        setDrawerTambahData( false );
    };
    const handleDeleteCustomer = async () =>
    {
        if ( !editRecordId )
        {
            message.warning( 'Tidak ada customer yang dipilih untuk dihapus.' );
            return;
        }

        try
        {
            setLoading( true );
            await axiosConfig.delete( `${ process.env.REACT_APP_LOCAL_API }/customer/${ editRecordId }` );
            message.success( 'Berhasil menghapus customer' );
            setDrawerCheckData( false ); // Pastikan drawer ditutup setelah penghapusan berhasil
            fetchData(); // Memperbarui data setelah penghapusan
        } catch ( error )
        {
            message.error( 'Gagal menghapus customer, coba lagi nanti.' );
        } finally
        {
            setLoading( false );
            setEditRecordId( null );
        }
    };
    return (
        <div>
            <div style={ { padding: '0 5px' } }>
                <div style={ { display: 'flex', gap: 5, padding: '10px 0' } }>
                    <Button onClick={ handleTambahData }>
                        Tambah Customer
                    </Button>
                    <Button onClick={ fetchData }>
                        Refresh
                    </Button>
                    <Input
                        placeholder="Cari Nama atau Alamat"
                        onChange={ handleSearch }
                        style={ { width: '100%' } }
                        prefix={ <SearchOutlined /> }
                    />
                </div>
                <Table
                    bordered
                    columns={ columns }
                    showSorterTooltip={ false }
                    dataSource={ filteredData }
                    rowKey="_id"
                    loading={ loading }
                    scroll={ { y: 340 } }
                    size="small"
                />
            </div>
            <div>
                <Drawer
                    title="Edit Pelanggan"
                    placement="right"
                    closable={ true }
                    onClose={ handleCloseEditDrawer }
                    visible={ drawerEditData }
                    width="50%"
                >
                    <EditCustomer
                        recordId={ editRecordId }
                        onClose={ handleCloseEditDrawer }
                        fetchData={ fetchData }
                    />
                </Drawer>
                <Drawer
                    title="Cek Data Pelanggan"
                    placement="right"
                    closable={ true }
                    onClose={ handleCloseCheckData }
                    visible={ drawerCheckData }
                    width="50%"
                    extra={
                        <Popconfirm
                            title="Hapus seluruh data customer ini ?"
                            onConfirm={ handleDeleteCustomer }
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button>Hapus Customer</Button>
                        </Popconfirm>
                    }
                >
                    <CekDataCustomer
                        recordId={ editRecordId }
                        onClose={ handleCloseCheckData }
                        fetchData={ fetchData }
                    />
                </Drawer>
                <Drawer
                    title="Customer Baru"
                    placement="right"
                    closable={ true }
                    onClose={ handleCloseTambahLahan }
                    visible={ drawerTambahData }
                    width="50%"
                >
                    <TambahCustomer onClose={ handleCloseTambahLahan } fetchData={ fetchData } />
                </Drawer>
            </div>
        </div>
    );
};

export default DataCustomer;
