import { Button, Form, Input, message, Modal, Select, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import CreateAxiosInstance from '../../../Authentication/contexts/axiosConfig';

const { Option } = Select;

const CreateAccount = ( { onClose, fetchData } ) =>
{
    const apiUrl = process.env.REACT_APP_LOCAL_API;
    const axiosConfig = CreateAxiosInstance();
    const [ form ] = Form.useForm();
    const [ loading, setLoading ] = useState( false );
    const [ customers, setCustomers ] = useState( [] );
    const [ client, setClient ] = useState( [] );

    const fetchClientList = async () =>
    {
        setLoading( true );
        try
        {
            const response = await axiosConfig.get( `${ apiUrl }/auth/client/list-available` );
            setCustomers( response.data );
        } catch ( error )
        {
            console.error( error );
            message.error( 'Gagal memuat daftar klien.' );
        } finally
        {
            setLoading( false );
        }
    };

    useEffect( () =>
    {
        fetchClientList();
    }, [] );

    const handleClientData = ( value ) =>
    {
        const selectedName = customers.filter( item => item.itemId === value )[ 0 ];
        setClient( selectedName );
        form.setFieldsValue( {
            clientKey: selectedName.itemKey
        } );
    };

    const generateEmail = () =>
    {
        const nameLowercase = client.itemName.toLowerCase();
        const nameWithoutSpaces = nameLowercase.replace( /\s+/g, '' );
        const nameMax8Chars = nameWithoutSpaces.substring( 0, 8 );
        const randomNumber = Math.floor( 1000 + Math.random() * 9000 );
        return `${ nameMax8Chars }${ randomNumber }@royaltanjung.com`;
    };

    const generatePassword = () =>
    {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let password = '';
        for ( let i = 0; i < 25; i++ )
        {
            const randomIndex = Math.floor( Math.random() * characters.length );
            password += characters[ randomIndex ];
        }
        return password;
    };

    const onFinish = async ( values ) =>
    {
        if ( !client.itemName && !values.clientKey )
        {
            return message.info( 'Data tidak lengkap!' );
        }
        const accountData = {
            name: client.itemName,
            key: values.clientKey,
            email: generateEmail( client.itemName ),
            role: 'CLIENT',
            password: generatePassword(),
        };
        try
        {
            setLoading( true );
            const response = await axiosConfig.post( `${ apiUrl }/auth/client/signup`, accountData );

            await fetchClientList();
            form.resetFields();

            if ( response.status === 201 )
            {
                Modal.confirm( {
                    title: 'Apakah Anda ingin menyalin kata sandi?',
                    content: <Input value={ accountData.password } />,
                    okText: 'Salin',
                    onOk: () =>
                    {
                        navigator.clipboard.writeText( accountData.password );
                        message.success( 'Kata sandi disalin ke clipboard' );
                    },
                    onCancel: () => { }
                } );
                message.success( response.data.message );
                fetchData();
                onClose();
            }
        } catch ( error )
        {
            if ( error.response.status === 400 )
            {
                message.info( 'Account already exist!' );
            } else
            {
                console.error( error );
                message.error( 'Gagal membuat akun klien.' );
            }
        } finally
        {
            setLoading( false );
        }
    };


    return (
        <Spin spinning={ loading }>
            <Form form={ form } layout='vertical' onFinish={ onFinish }>
                <Form.Item
                    style={ { width: '100%' } }
                    rules={ [ { required: true, message: 'Silakan pilih klien' } ] }
                >
                    <Select
                        placeholder='Pilih Nama Client'
                        showSearch
                        onChange={ handleClientData }
                    >
                        { customers.map( item => (
                            <Option key={ item.itemId } value={ item.itemId }>
                                { `${ item.itemName.slice( 0, 15 ) } - ${ item.itemBlock }` }
                            </Option>
                        ) ) }
                    </Select>
                </Form.Item>
                <Form.Item
                    name='clientKey'
                    style={ { width: '100%' } }
                    rules={ [ { required: true, message: 'Pilih Blok Kavling' } ] }
                >
                    <Input readOnly />
                </Form.Item>
                <Form.Item>
                    <Button type='primary' htmlType='submit'>
                        Create Client Account
                    </Button>
                </Form.Item>
            </Form>
        </Spin>
    );
};

export default CreateAccount;
