import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { Form, InputNumber, DatePicker, message, Button, Spin, Popconfirm } from 'antd';
import CreateAxiosInstance from '../../../Authentication/contexts/axiosConfig';

const EditGajiTanggal = ( { fetchData, onClose, recordId } ) =>
{
    const axiosConfig = CreateAxiosInstance();
    const [ form ] = Form.useForm();
    const [ loading, setLoading ] = useState( true );
    const [ gajiData, setGajiData ] = useState();

    useEffect( () =>
    {
        const fetchEmployeeById = async () =>
        {
            setLoading( true );
            try
            {
                const response = await axiosConfig.get( `${ process.env.REACT_APP_LOCAL_API }/salaryMan/data/${ recordId }` );
                const data = response.data;
                form.setFieldsValue( {
                    ...data,
                    tanggalGaji: dayjs( data.tanggalGaji, 'DD/MM/YYYY' ),
                } );
                setGajiData( data )
            } catch ( error )
            {
                onClose();
                message.error( 'Gagal mengambil data dari server' );
            }
            finally
            {
                setLoading( false );
            }
        };

        if ( recordId )
        {
            fetchEmployeeById();
        }
    }, [ recordId, form, onClose ] );

    const onFinish = async ( values ) =>
    {
        const totalPaymentEmployee = ( gajiData.totalGajiMingguan || 0 ) + ( gajiData.totalGajiLembur || 0 );
        const paymentData = {
            ...values,
            tanggalGaji: dayjs( values.tanggalGaji ).format( 'DD/MM/YYYY' ),
            uangMakanTukang: values.uangMakanTukang,
            totalPembayaranGaji: totalPaymentEmployee + values.uangMakanTukang,
        };
        try
        {
            setLoading( true );
            await axiosConfig.put( `${ process.env.REACT_APP_LOCAL_API }/salaryMan / data / ${ recordId }`, paymentData )
            form.resetFields();
            onClose();
            fetchData();
            message.success( 'Data gaji berhasil diperbarui' );
        } catch ( error )
        {
            message.error( 'Data gagal diperbarui' );
        } finally
        {
            setLoading( false );
        }
    };

    return (
        <Spin spinning={ loading }>
            <Form layout="vertical" onFinish={ onFinish } form={ form }>
                <div style={ { display: 'flex', gap: 5 } }>
                    <Form.Item
                        name="uangMakanTukang"
                        label="Uang Makan Tukang"
                        style={ { width: '100%' } }
                    >
                        <InputNumber
                            addonBefore='Rp'
                            placeholder='Masukkan gaji'
                            style={ { width: '100%' } } min={ 0 }
                        />
                    </Form.Item>
                    <Form.Item
                        name="tanggalGaji"
                        label="Tanggal Gajian"
                        style={ { width: '100%' } }
                        rules={ [ { required: true, message: 'Tanggal gajian diperlukan!' } ] }
                    >
                        <DatePicker style={ { width: '100%' } } format="DD/MM/YYYY" />
                    </Form.Item>
                </div>
                <div style={ { display: 'flex', justifyContent: 'space-between' } }>
                    <Form.Item>
                        <Popconfirm
                            placement='left'
                            title="Apakah kamu yakin melakukan update data ini ?"
                            onConfirm={ () => form.submit() }
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button style={ { marginTop: 10, width: '200px' } } type='primary' >
                                Update Gaji Harian
                            </Button>
                        </Popconfirm>
                    </Form.Item>
                </div>
            </Form>
        </Spin>
    );
};

export default EditGajiTanggal;
