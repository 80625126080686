import React, { useEffect, useRef, useState } from 'react';
import { Card, message, notification, Progress, Spin, Statistic } from 'antd';
import { useAuth } from '../Authentication/contexts/authContext';
import './home.css';
import { RiEqualLine } from "react-icons/ri";
import dayjs from 'dayjs';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import 'dayjs/locale/id'; // Import bahasa Indonesia untuk dayjs
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import CreateAxiosInstance from '../Authentication/contexts/axiosConfig';
dayjs.extend( isSameOrBefore );

const Homepage = () =>
{
    const axiosConfig = CreateAxiosInstance();
    const apiUrl = process.env.REACT_APP_LOCAL_API;
    const { userData } = useAuth();
    const hasNotified = useRef( false );
    const [ loading, setLoading ] = useState( false );
    const [ currentTime, setCurrentTime ] = useState( dayjs() );
    const [ dataCustomer, setDataCustomer ] = useState( [] );
    const [ dataPemasukan, setDataPemasukan ] = useState( [] );
    const [ dataPengeluaran, setDataPengeluaran ] = useState( [] );
    const [ dpTerbayar, setDpTerbayar ] = useState( 0 );
    const [ dpTertagih, setDpTertagih ] = useState( 0 );
    const [ angsuranTertagih, setAngsuranTertagih ] = useState( 0 );
    const [ angsuranTerbayar, setAngsuranTerbayar ] = useState( 0 );
    const [ totalAngsuran, setTotalAngsuran ] = useState( 0 );
    const [ sisaUserAngsuran, setSisaUserAngsuran ] = useState( 0 );
    const [ totalUserAngsuran, setTotalUserAngsuran ] = useState( 0 );
    const [ persenAngs, setPersenAngs ] = useState( 0 );
    const [ totalJumlahDP, setTotalJumlahDP ] = useState( 0 );
    const [ sisaUserDP, setSisaUserDP ] = useState( 0 );
    const [ totalUserDP, setTotalUserDP ] = useState( 0 );
    const [ persenDP, setPersenDP ] = useState( 0 );
    const [ angsuranBulanIni, setAngsuranBulanIni ] = useState( 0 );
    const [ dPBulanIni, setDPBulanIni ] = useState( 0 );
    const [ totalUangMasuk, setTotalUangMasuk ] = useState( [] );
    const [ totalUangKeluar, setTotalUangKeluar ] = useState( [] );
    const [ lahanData, setLahanData ] = useState( [] );
    const [ materialData, setMaterialData ] = useState( [] );
    const [ salesData, setSalesData ] = useState( [] );
    const [ mediaData, setMediaData ] = useState( [] );
    const [ gajiTukangData, setGajiTukangData ] = useState( [] );
    const [ gajiKaryawanData, setGajiKaryawanData ] = useState( [] );
    const [ pinjamanData, setPinjamanData ] = useState( [] );
    const [ angsuranPaidBefore, setAngsuranPaidBefore ] = useState( 0 );
    const [ differenceReceivables, setDifferenceReceivables ] = useState( 0 );
    const [ differenceReceivablesBefore, setDifferenceReceivablesBefore ] = useState( 0 );
    useEffect( () =>
    {
        const fetchData = async () =>
        {
            setLoading( true );
            try
            {
                const loginValidate = await axiosConfig.post( `${ apiUrl }/api/auth/validate-status` );
                if ( loginValidate.status === 200 )
                {
                    try
                    {
                        const [
                            customersRes,
                            pengeluaranRes,
                            pemasukanRes,
                            lahanRes,
                            materialRes,
                            salesRes,
                            mediaRes,
                            gajiTukangRes,
                            gajiKaryawanRes,
                            pinjamanRes
                        ] = await Promise.all( [
                            axiosConfig.get( `${ apiUrl }/customer/data` ),
                            axiosConfig.get( `${ apiUrl }/additionalExpenses/list` ),
                            axiosConfig.get( `${ apiUrl }/additionalIncome/data` ),
                            axiosConfig.get( `${ apiUrl }/lahan/list/data` ),
                            axiosConfig.get( `${ apiUrl }/materials/list` ),
                            axiosConfig.get( `${ apiUrl }/sales/data` ),
                            axiosConfig.get( `${ apiUrl }/media/payment` ),
                            axiosConfig.get( `${ apiUrl }/salaryMan/data` ),
                            axiosConfig.get( `${ apiUrl }/employee/list` ),
                            axiosConfig.get( `${ apiUrl }/loan/list` ),
                        ] );

                        setLahanData( lahanRes.data );
                        setMaterialData( materialRes.data );
                        setSalesData( salesRes.data );
                        setMediaData( mediaRes.data );
                        setGajiTukangData( gajiTukangRes.data );
                        setGajiKaryawanData( gajiKaryawanRes.data );
                        setPinjamanData( pinjamanRes.data );
                        setDataCustomer( customersRes.data );
                        setDataPemasukan( pemasukanRes.data );
                        setDataPengeluaran( pengeluaranRes.data );

                    } catch ( error )
                    {
                        message.error( 'Gagal memuat data' );
                    }
                } else
                {
                    message.error( 'Koneksi server error' );
                }
            } catch ( error )
            {
                message.error( 'Gagal memvalidasi login' );
            } finally
            {
                setLoading( false );
            }
        };

        fetchData();
    }, [] );

    useEffect( () =>
    {
        if ( !hasNotified.current && userData.name )
        {
            notification.open( {
                message: 'Selamat Datang!',
                description: `Halo ${ userData.name }, selamat datang di aplikasi kami!`,
                placement: 'topRight',
            } );
            hasNotified.current = true;
        }
    }, [ userData ] );
    useEffect( () =>
    {
        const intervalId = setInterval( () =>
        {
            setCurrentTime( dayjs() );
        }, 1000 );
        return () => clearInterval( intervalId );
    }, [] );
    const convertToIDR = ( value ) =>
    {
        return new Intl.NumberFormat( 'id-ID', {
            style: 'currency',
            currency: 'IDR',
            maximumFractionDigits: 0,
        } ).format( value );
    };
    useEffect( () =>
    {
        const selectedDate = dayjs();
        const endOfMonth = dayjs( selectedDate ).endOf( 'month' );
        const today = dayjs( selectedDate, 'day' )
        const dataAngsuran = dataCustomer.filter( a => a.statusPembelian === 'BELUM LUNAS' );
        const dataDP = dataCustomer.filter( customer =>
        {
            const totalDP = customer.pembayaranDP.reduce( ( total, dp ) => total + ( dp.jumlahByrDp || 0 ), 0 );
            return totalDP < customer.uangMukaKavling;
        } );
        // Data Angsuran
        const angsuranData = dataAngsuran.map( db =>
        {
            const pembayaranAngsuranFiltered = db.pembayaranAngsuran.filter( item =>
                dayjs( item.tanggalByrAngs2 || item.tanggalByrAngs, 'DD/MM/YYYY' ).isSame( selectedDate, 'month' )
            );
            const firstAngsuran = db.pembayaranAngsuran[ 0 ]
                ? ( db.pembayaranAngsuran[ 0 ].tanggalByrAngs2 || db.pembayaranAngsuran[ 0 ].tanggalByrAngs )
                : '';
            const angsuranCustomer = db.batasAngsuran2
                ? dayjs().isSameOrBefore( db.batasAngsuran2, 'DD/MM/YYYY' )
                    ? db.angsuranKavling : db.angsuranKavling2
                : db.angsuranKavling;
            return {
                dp: db.uangMukaKavling,
                byrDp: db.pembayaranAngsuranDP,
                angsuran1: firstAngsuran,
                angsuran: angsuranCustomer,
                jumlahByrAngsr: pembayaranAngsuranFiltered.length > 0
                    ? pembayaranAngsuranFiltered.map( item => convertToIDR( item.jumlahByrAngs ) ).join( ", " )
                    : convertToIDR( 0 ),
            };
        } );
        const setDataAngs = angsuranData.filter( item =>
            dayjs( item.angsuran1, 'DD/MM/YYYY' ).isSameOrBefore( endOfMonth )
        );
        const totalByrAngs = setDataAngs.reduce( ( total, item ) =>
        {
            const jumlahByrNumbers = item.jumlahByrAngsr
                .split( ', ' )
                .map( value => parseFloat( value.replace( /[^\d]/g, '' ) ) )
                .filter( value => !isNaN( value ) );
            return total + jumlahByrNumbers.reduce( ( acc, value ) => acc + value, 0 );
        }, 0 );
        const totalBlmByr = setDataAngs
            .filter( f => f.jumlahByrAngsr === convertToIDR( 0 ) )
            .reduce( ( total, item ) => total + ( item.angsuran || 0 ), 0 )
            +
            setDataAngs
                .filter( f => f.jumlahByrAngsr > convertToIDR( 0 ) && f.jumlahByrAngsr !== f.angsuran )
                .reduce( ( total, item ) =>
                {
                    const jumlahByrArray = item.jumlahByrAngsr.split( ', ' )
                        .map( value => parseFloat( value.replace( /[^\d]/g, '' ) ) )
                        .filter( value => !isNaN( value ) );
                    const totalJumlahByr = jumlahByrArray.length > 0 ? jumlahByrArray[ 0 ] : 0;
                    return total + ( ( item.angsuran || 0 ) - totalJumlahByr );
                }, 0 );
        const userBlmByr = setDataAngs.filter( db => db.jumlahByrAngsr === convertToIDR( 0 ) ).length;
        const totalAngsuranSeharusnya = dataAngsuran.reduce( ( total, db ) => total + ( db.angsuranKavling || 0 ), 0 );
        const percentAngs = totalAngsuranSeharusnya > 0 ? ( totalByrAngs / totalAngsuranSeharusnya ) * 100 : 0;
        const percentAngsRounded = percentAngs.toFixed( 2 );
        const angsuranDataBefore = dataAngsuran.map( db =>
        {
            const previousMonth = dayjs().subtract( 1, 'month' );
            const pembayaranAngsuranFiltered = db.pembayaranAngsuran.filter( item =>
                dayjs( item.tanggalByrAngs2 || item.tanggalByrAngs, 'DD/MM/YYYY' ).isSame( previousMonth, 'month' )
            );
            const firstAngsuran = db.pembayaranAngsuran[ 0 ]
                ? ( db.pembayaranAngsuran[ 0 ].tanggalByrAngs2 || db.pembayaranAngsuran[ 0 ].tanggalByrAngs )
                : '';
            const angsuranCustomer = db.batasAngsuran2
                ? dayjs().isSameOrBefore( db.batasAngsuran2, 'DD/MM/YYYY' )
                    ? db.angsuranKavling : db.angsuranKavling2
                : db.angsuranKavling;
            return {
                byrDp: db.pembayaranAngsuranDP,
                angsuran1: firstAngsuran,
                angsuran: angsuranCustomer,
                jumlahByrAngsr: pembayaranAngsuranFiltered.length > 0
                    ? pembayaranAngsuranFiltered.map( item => convertToIDR( item.jumlahByrAngs ) ).join( ", " )
                    : convertToIDR( 0 ),
            };
        } );
        const setDataAngsBefore = angsuranDataBefore.filter( item =>
            dayjs( item.angsuran1, 'DD/MM/YYYY' ).isSameOrBefore( endOfMonth )
        );
        const totalByrAngsBefore = setDataAngsBefore.reduce( ( total, item ) =>
        {
            const jumlahByrNumbers = item.jumlahByrAngsr
                .split( ', ' )
                .map( value => parseFloat( value.replace( /[^\d]/g, '' ) ) )
                .filter( value => !isNaN( value ) );
            return total + jumlahByrNumbers.reduce( ( acc, value ) => acc + value, 0 );
        }, 0 );
        const totalBlmByrBefore = setDataAngsBefore
            .filter( f => f.jumlahByrAngsr === convertToIDR( 0 ) )
            .reduce( ( total, item ) => total + ( item.angsuran || 0 ), 0 )
            +
            setDataAngsBefore
                .filter( f => f.jumlahByrAngsr > convertToIDR( 0 ) && f.jumlahByrAngsr !== f.angsuran )
                .reduce( ( total, item ) =>
                {
                    const jumlahByrArray = item.jumlahByrAngsr.split( ', ' )
                        .map( value => parseFloat( value.replace( /[^\d]/g, '' ) ) )
                        .filter( value => !isNaN( value ) );
                    const totalJumlahByr = jumlahByrArray.length > 0 ? jumlahByrArray[ 0 ] : 0;
                    return total + ( ( item.angsuran || 0 ) - totalJumlahByr );
                }, 0 );
        // Data DP
        const dpData = dataDP.map( db =>
        {
            const dpTrbyr = db.pembayaranDP
                .filter( item => dayjs( item.tanggalByrDp, 'DD/MM/YYYY' )
                    .isSameOrBefore( endOfMonth ) );
            return {
                dp: db.uangMukaKavling,
                dpDbyr: dpTrbyr.length > 0
                    ? dpTrbyr.map( item => convertToIDR( item.jumlahByrDp ) ).join( ", " )
                    : convertToIDR( 0 ),
                tglByrDp: dpTrbyr.map( item => item.tanggalByrDp ).join( ", " ),
                dPTertagih: db.uangMukaKavling - ( ( dpTrbyr
                    .reduce( ( total, item ) => total + ( item.jumlahByrDp || 0 ), 0 ) ) + db.jumlahUTJ ),
                tglBooking: db.tanggalBooking,
                tglIJB: db.tanggalIJB,
            };
        } );
        const setDataDP = dpData.filter( item =>
            dayjs( item.tglBooking || item.tglIJB, 'DD/MM/YYYY' ).isSameOrBefore( endOfMonth )
        );
        const totalByrDp = setDataDP.reduce( ( total, item ) =>
        {
            const jumlahByrNumbers = item.dpDbyr
                .split( ', ' )
                .map( value => parseFloat( value.replace( /[^\d]/g, '' ) ) )
                .filter( value => !isNaN( value ) );
            return total + jumlahByrNumbers.reduce( ( acc, value ) => acc + value, 0 );
        }, 0 );
        const userBlmByrDP = ( setDataDP.length ) - ( setDataDP.filter( sd => sd.dPTertagih === 0 ).length );
        const totalDPSeharusnya = dpData.reduce( ( total, db ) => total + ( db.dp || 0 ), 0 );
        const percentDP = totalDPSeharusnya > 0 ? ( totalByrDp / totalDPSeharusnya ) * 100 : 0;
        const percentDPRounded = percentDP.toFixed( 2 );
        const sisaDp = setDataDP.reduce( ( total, item ) => total + ( item.dPTertagih || 0 ), 0 );
        // Data Denda
        const dendaData = dataAngsuran.map( dd =>
        {
            const pembayaranDenda = dd.dataBayarDenda ? dd.dataBayarDenda.filter( item =>
                dayjs( item.tanggalByrDenda, 'DD/MM/YYYY' ).isSame( selectedDate, 'day' )
            ) : [];
            return {
                jumlahByrDd: pembayaranDenda.length > 0
                    ? pembayaranDenda.map( item => convertToIDR( item.jumlahByrDenda ) ).join( ", " )
                    : convertToIDR( 0 ),
            }
        } );
        const jumlahDendaBayar = dendaData.reduce( ( total, item ) =>
        {
            const jumlahByrNumbers = item.jumlahByrDd
                .split( ', ' )
                .map( value => parseFloat( value.replace( /[^\d]/g, '' ) ) )
                .filter( value => !isNaN( value ) );
            return total + jumlahByrNumbers.reduce( ( acc, value ) => acc + value, 0 );
        }, 0 );
        // Data Air & Lampu
        const dataAirLampu = dataCustomer.map( dd =>
        {
            const pembayaranAir = dd.pembayaranAirLampu.filter( item =>
                dayjs( item.tanggalByrAir, 'DD/MM/YYYY' ).isSame( selectedDate, 'day' )
            );
            const pembayaranLm = dd.pembayaranAirLampu.filter( item =>
                dayjs( item.tanggalByrLm, 'DD/MM/YYYY' ).isSame( selectedDate, 'day' )
            );
            return {
                bayarAir: pembayaranAir.length > 0
                    ? pembayaranAir.map( item => convertToIDR( item.jumlahByrAir ) ).join( ", " )
                    : convertToIDR( 0 ),
                bayarLm: pembayaranLm.length > 0
                    ? pembayaranLm.map( item => convertToIDR( item.jumlahByrLm ) ).join( ", " )
                    : convertToIDR( 0 ),
            }
        } );
        const totalByrAir = dataAirLampu.reduce( ( total, item ) =>
        {
            const jumlahByrNumbers = item.bayarAir
                .split( ', ' )
                .map( value => parseFloat( value.replace( /[^\d]/g, '' ) ) )
                .filter( value => !isNaN( value ) );
            return total + jumlahByrNumbers.reduce( ( acc, value ) => acc + value, 0 );
        }, 0 );
        const totalByrLm = dataAirLampu.reduce( ( total, item ) =>
        {
            const jumlahByrNumbers = item.bayarLm
                .split( ', ' )
                .map( value => parseFloat( value.replace( /[^\d]/g, '' ) ) )
                .filter( value => !isNaN( value ) );
            return total + jumlahByrNumbers.reduce( ( acc, value ) => acc + value, 0 );
        }, 0 );
        // Data Pemasukan
        const filterPemasukan = dataPemasukan
            .filter( dp => dayjs( dp.dateGet, 'DD/MM/YYYY' ).isSame( selectedDate, 'day' ) );
        const uangMasuk = filterPemasukan.reduce( ( total, item ) => total + ( item.howMuch || 0 ), 0 );
        const dataAngs = angsuranData
            .filter( angs => dayjs( angs.jumlahByrAngsr ).isSame( selectedDate, 'day' ) );
        const angsHariIni = dataAngs.reduce( ( total, item ) =>
        {
            const jumlahByrNumbers = item.jumlahByrAngsr
                .split( ', ' )
                .map( value => parseFloat( value.replace( /[^\d]/g, '' ) ) )
                .filter( value => !isNaN( value ) );
            return total + jumlahByrNumbers.reduce( ( acc, value ) => acc + value, 0 );
        }, 0 );
        const datasdp = dpData
            .filter( dp => dayjs( dp.tglByrDp, 'DD/MM/YYYY' ).isSame( selectedDate, 'day' ) );
        const dpHariIni = datasdp.reduce( ( total, item ) =>
        {
            const jumlahByrNumbers = item.dpDbyr
                .split( ', ' )
                .map( value => parseFloat( value.replace( /[^\d]/g, '' ) ) )
                .filter( value => !isNaN( value ) );
            return total + jumlahByrNumbers.reduce( ( acc, value ) => acc + value, 0 );
        }, 0 );
        // Data Pengeluaran
        const filterPengeluaran = dataPengeluaran
            .filter( dp => dayjs( dp.tanggalPTT, 'DD/MM/YYYY' ).isSame( selectedDate, 'day' ) );
        const uangKeluar = filterPengeluaran.reduce( ( total, item ) => total + item.hargaPTT, 0 );
        const byrLahan = lahanData.map( ld =>
        {
            const filterLandPay = ld.landPayment
                .filter( lp => dayjs( lp.tanggalByrLhn, 'DD/MM/YYYY' ).isSame( selectedDate, 'day' ) );
            return {
                petani: ld.farmerName,
                tanggalBayar: filterLandPay.map( mp => mp.tanggalByrLhn ),
                jumlahBayar: filterLandPay.map( mp => mp.jumlahByrLhn || 0 ),
            };
        } );
        const bayarLahan = byrLahan.reduce( ( total, item ) =>
        {
            const jumlahBayarTotal = item.jumlahBayar.reduce( ( sum, bayar ) => sum + bayar, 0 );
            return total + jumlahBayarTotal;
        }, 0 );
        const byrMaterial = materialData
            .filter( mt => dayjs( mt.tanggalBeliMaterial, 'DD/MM/YYYY' ).isSame( today ) );
        const bayarMaterial = byrMaterial.reduce( ( total, item ) => total + ( item.hargaMaterial || 0 ), 0 );
        const byrKomisi = salesData.map( km =>
        {
            const filterKomisi = km.dataKomisi
                .filter( dk => dayjs( dk.tanggalByrKms, 'DD/MM/YYYY' ).isSame( today ) );
            return {
                jumlahBayar: filterKomisi.map( fk => convertToIDR( fk.jumlahByrKms ) )
            };
        } );
        const bayarKomisi = byrKomisi.reduce( ( total, item ) => total + ( item.jumlahBayar || 0 ), 0 );
        const byrMedia = mediaData
            .filter( md => dayjs( md.tanggalKasih, 'DD/MM/YYYY' ).isSame( today ) );
        const bayarMedia = byrMedia.reduce( ( total, item ) => total + ( item.jumlahKasih || 0 ), 0 );
        const byrTukang = gajiTukangData
            .filter( gtd => dayjs( gtd.tanggalGaji, 'DD/MM/YYYY' ).isSame( today ) );
        const bayarTukang = byrTukang.reduce( ( total, item ) => total + ( item.totalPembayaranGaji || 0 ), 0 )
        const byrKaryawan = gajiKaryawanData.map( gkd =>
        {
            const gajiKD = gkd.dataSalary
                .filter( ds => dayjs( ds.dateSalary, 'DD/MM/YYYY' ).isSame( today ) );
            return {
                gajiPK: gajiKD.map( gp => convertToIDR( gp.salary || 0 ) ),
                gajiTK: gajiKD.map( gp => convertToIDR( gp.additionalSalary || 0 ) ),
            };
        } );
        const bayarKaryawan = byrKaryawan
            .reduce( ( total, item ) => ( total + ( item.gajiPK || 0 ) )
                + ( total + ( item.gajiTK || 0 ) ), 0 )
        const dataKasbon = pinjamanData.map( pd =>
        {
            const dataPinjaman = pd.riwayatPinjaman
                .filter( rp => dayjs( rp.loanDate, 'DD/MM/YYYY' ).isSame( today ) );
            const dataPembayaran = pd.riwayatPembayaran
                .filter( rp => dayjs( rp.paymentDate, 'DD/MM/YYYY' ).isSame( today ) );
            return {
                bayarPinjaman: dataPinjaman.map( dp => convertToIDR( dp.loanValue ) ),
                tambahPinjaman: dataPembayaran.map( dp => convertToIDR( dp.paymentValue ) ),
            };
        } );
        const bayarPinjaman = dataKasbon.reduce( ( total, item ) => total + ( item.bayarPinjaman || 0 ), 0 );
        const pinjamanBaru = dataKasbon.reduce( ( total, item ) => total + ( item.tambahPinjaman || 0 ), 0 );
        setTotalUangMasuk(
            Number( uangMasuk )
            + Number( angsHariIni )
            + Number( jumlahDendaBayar )
            + Number( totalByrAir )
            + Number( totalByrLm )
            + Number( dpHariIni )
            + Number( bayarPinjaman ) );
        setTotalUangKeluar(
            Number( uangKeluar )
            + Number( pinjamanBaru )
            + Number( bayarLahan )
            + Number( bayarMaterial )
            + Number( bayarKomisi )
            + Number( bayarMedia )
            + Number( bayarTukang )
            + Number( bayarKaryawan ) );
        setDifferenceReceivables( totalBlmByr );
        setDifferenceReceivablesBefore( totalBlmByrBefore );
        setAngsuranPaidBefore( totalByrAngsBefore );
        setPersenAngs( percentAngsRounded );
        setTotalUserAngsuran( dataAngsuran.length )
        setSisaUserAngsuran( userBlmByr );
        setTotalAngsuran( totalAngsuranSeharusnya );
        setTotalJumlahDP( totalDPSeharusnya );
        setSisaUserDP( userBlmByrDP );
        setTotalUserDP( dataDP.length );
        setPersenDP( percentDPRounded );
        setDpTerbayar( totalByrDp );
        setDpTertagih( sisaDp );
        setDPBulanIni( totalByrDp + sisaDp );
        setAngsuranBulanIni( totalBlmByr + totalByrAngs )
        setAngsuranTertagih( totalBlmByr );
        setAngsuranTerbayar( totalByrAngs );
    }, [ dataCustomer, dataPemasukan, dataPengeluaran, gajiKaryawanData, gajiTukangData, salesData, mediaData, lahanData, pinjamanData, materialData ] );
    const conicColors = {
        '0%': '#87d068',
        '50%': '#ffe58f',
        '100%': '#ffccc7',
    };
    return (
        <div>
            <Spin spinning={ loading }>
                <div className='homeDate'>
                    <Statistic title="Waktu Sekarang" value={ currentTime.format( 'HH:mm:ss' ) } />
                    <Statistic title="Hari" value={ currentTime.format( 'dddd' ) } />
                    <Statistic title="Bulan" value={ currentTime.format( 'MMMM' ) } />
                    <Statistic
                        title="Tahun"
                        value={ currentTime.format( 'YYYY' ) }
                        formatter={ value => value.replace( /,/g, '' ) }
                    />
                </div>
                <div className='homeSect'>
                    <Card className='homeCardStyle'>
                        <div className='homeCard'>
                            <Statistic
                                title='Total Uang Masuk Hari Ini'
                                value={ convertToIDR( totalUangMasuk ) } />
                        </div>
                    </Card>
                    <Card className='homeCardStyle'>
                        <div className='homeCard'>
                            <Statistic
                                title='Total Uang Keluar Hari Ini'
                                value={ convertToIDR( totalUangKeluar ) } />
                        </div>
                    </Card>
                </div>
                <div className='homeSect'>
                    <Card className='homeCardStyle'>
                        <div className='homeCard'>
                            <Statistic
                                title='User Belum Bayar Angsuran Bulanan'
                                value={ sisaUserAngsuran }
                                suffix={ `/ ${ totalUserAngsuran }` } />
                            <Statistic title='Angsuran Bulan Ini' value={ convertToIDR( angsuranBulanIni ) } />
                            <Statistic
                                title='Jumlah Tunggakan Angsuran Bulanan'
                                value={ convertToIDR( angsuranTertagih ) }
                                prefix={ differenceReceivablesBefore === differenceReceivables
                                    ? <RiEqualLine style={ { color: 'blue' } } />
                                    : differenceReceivablesBefore > differenceReceivables
                                        ? <ArrowUpOutlined style={ { color: 'green' } } />
                                        : <ArrowDownOutlined style={ { color: 'red' } } /> } />
                            <Statistic
                                title='Jumlah Angsuran Terbayar Bulanan'
                                value={ convertToIDR( angsuranTerbayar ) }
                                suffix={ `/ ${ convertToIDR( totalAngsuran ) }` }
                                prefix={ angsuranPaidBefore === angsuranTerbayar
                                    ? <RiEqualLine style={ { color: 'blue' } } />
                                    : angsuranPaidBefore > angsuranTerbayar
                                        ? <ArrowDownOutlined style={ { color: 'red' } } />
                                        : <ArrowUpOutlined style={ { color: 'green' } } /> } />
                            <Progress percent={ persenAngs } strokeColor={ conicColors } />
                        </div>
                    </Card>
                    <Card className='homeCardStyle'>
                        <div className='homeCard'>
                            <Statistic
                                title='User Belum Lunas DP'
                                value={ sisaUserDP }
                                suffix={ `/ ${ totalUserDP }` } />
                            <Statistic title='Dp Bulan Ini' value={ convertToIDR( dPBulanIni ) } />
                            <Statistic title='Jumlah Tunggakan Dp' value={ convertToIDR( dpTertagih ) } />
                            <Statistic title='Jumlah Dp Terbayar' value={ convertToIDR( dpTerbayar ) }
                                suffix={ `/ ${ convertToIDR( totalJumlahDP ) }` } />
                            <Progress percent={ persenDP } strokeColor={ conicColors } />
                        </div>
                    </Card>
                </div>
            </Spin>
        </div>
    );
};

export default Homepage;
