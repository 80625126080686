import React, { useEffect, useState } from 'react';
import { Form, Input, Button, message, Spin } from 'antd';
import CreateAxiosInstance from '../../../Authentication/contexts/axiosConfig';

const TambahWartawan = ( { fetchData, onClose } ) =>
{
    const [ form ] = Form.useForm();
    const axiosConfig = CreateAxiosInstance();
    const [ loading, setLoading ] = useState( true );
    const [ listMedia, setListMedia ] = useState( [] );

    useEffect( () =>
    {
        const fetchData = async () =>
        {
            setLoading( true );
            try
            {
                const response = await axiosConfig.get( `${ process.env.REACT_APP_LOCAL_API }/media/list` );
                setListMedia( response.data );
            } catch ( error )
            {
                onClose();
                message.error( 'Gagal mengambil data dari server' );
            } finally
            {
                setLoading( false );
            }
        };

        fetchData();
    }, [] );

    const onFinish = async ( values ) =>
    {
        const isDuplicate = listMedia.some(
            ( item ) =>
                item.name === values.name &&
                item.cardId === values.cardId &&
                item.zone === values.zone
        );

        if ( isDuplicate )
        {
            message.error(
                `Data ${ values.name } sudah ada di ${ values.cardId } wilayah ${ values.zone }`
            );
            return;
        }
        const newData = {
            ...values,
            name: values.name,
            cardId: values.cardId,
            zone: values.zone,
            type: 'MEDIA',
        };
        try
        {
            setLoading( true );
            await axiosConfig.post( `${ process.env.REACT_APP_LOCAL_API }/media/list`, newData );
            message.success( 'Media baru berhasil ditambahkan' );
            form.resetFields();
            fetchData();
            onClose();
        } catch ( error )
        {
            message.error( 'Gagal menambahkan media baru' );
        } finally
        {
            setLoading( false );
        }
    };

    return (
        <Spin spinning={ loading }>
            <Form layout="vertical" onFinish={ onFinish } form={ form }>
                <Form.Item
                    name="name"
                    label="Nama Wartawan"
                    rules={ [ { required: true, message: 'Nama wartawan diperlukan!' } ] }
                    normalize={ ( value ) => value && value.toUpperCase() }
                >
                    <Input placeholder="Masukkan Nama Wartawan" style={ { width: '100%' } } />
                </Form.Item>
                <Form.Item
                    name="cardId"
                    label="Nama Media"
                    rules={ [ { required: true, message: 'Nama media diperlukan!' } ] }
                    normalize={ ( value ) => value && value.toUpperCase() }
                >
                    <Input placeholder="Masukkan Nama Media" style={ { width: '100%' } } />
                </Form.Item>
                <Form.Item
                    label='Wilayah'
                    name='zone'
                    normalize={ ( value ) => value.toUpperCase() }
                    rules={ [ { pattern: /^[A-Z\s]*$/, message: 'Karakter tidak valid!' } ] }
                >
                    <Input placeholder='Masukkan Wilayah' />
                </Form.Item>
                <Form.Item
                    label='Masa Aktif'
                    name='activePeriod'
                    normalize={ ( value ) => value.toUpperCase() }
                >
                    <Input placeholder='Masukkan Wilayah' />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </Spin>
    );
};

export default TambahWartawan;
