import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { Layout, Spin, message } from 'antd';
import { useAuth } from '../contexts/authContext';
import DefaultSidebar from '../../components/Function/SidebarDefault';
import AlarmReminder from '../../components/Function/alarm';
import LimitAccess from '../../components/Function/limitAccess';
import axios from 'axios';
import dayjs from 'dayjs';
import NotificationAngsuranCust from '../../components/Function/NotificationAngsuranCust';
const isBetween = require( 'dayjs/plugin/isBetween' );
dayjs.extend( isBetween );

const { Content } = Layout;

const ProtectedRouteWithLayout = ( { children } ) =>
{
    const apiUrl = process.env.REACT_APP_LOCAL_API;
    const { isAuthenticated, isLoading, userData } = useAuth();
    const [ isInWorkingHours, setIsInWorkingHours ] = useState( true );
    const [ hasAccessPin, setHasAccessPin ] = useState( false );

    useEffect( () =>
    {
        const checkWorkingHours = () =>
        {
            const now = dayjs();
            const start = dayjs().hour( 8 ).minute( 29 );
            const end = dayjs().hour( 15 ).minute( 29 );
            setIsInWorkingHours( !now.isBetween( start, end, null, '[]' ) );
        };

        checkWorkingHours();
        const interval = setInterval( checkWorkingHours, 60000 );

        return () => clearInterval( interval );
    }, [] );

    useEffect( () =>
    {
        const storedAccessKey = JSON.parse( localStorage.getItem( 'access_key' ) );
        if ( storedAccessKey && storedAccessKey.keyAccess )
        {
            const validateAccessKey = async () =>
            {
                try
                {
                    const response = await axios.post( `${ apiUrl }/api/access-pin/validate`, { pin: storedAccessKey.keyAccess } );
                    if ( response.status === 200 )
                    {
                        setHasAccessPin( true );
                    }
                } catch ( error )
                {
                    localStorage.removeItem( 'access_key' );
                    message.error( 'Akses PIN berakhir, akses dicabut!' );
                    setHasAccessPin( false );
                }
            };

            validateAccessKey();

            const pinVerificationInterval = setInterval( validateAccessKey, 60000 );

            return () => clearInterval( pinVerificationInterval );
        }
    }, [ apiUrl ] );

    if ( isLoading )
    {
        return (
            <div style={ { display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' } }>
                <Spin size="middle" />
            </div>
        );
    };

    if ( !isAuthenticated )
    {
        return <Navigate to="/unauthorized" />;
    }

    if ( isInWorkingHours && !hasAccessPin && userData.role !== 'Developer' )
    {
        return <LimitAccess />;
    }

    return (
        <Spin spinning={ isLoading }>
            <Layout style={ { backgroundColor: 'white' } }>
                {/* <NotificationAngsuranCust /> */ }
                <div className="barside">
                    <DefaultSidebar />
                </div>
                <AlarmReminder />
                <Content>{ children }</Content>
            </Layout>
        </Spin>
    );

}

export default ProtectedRouteWithLayout;
