import React, { useState, useEffect } from 'react';
import { Form, Input, Button, message, Spin, Select, InputNumber, DatePicker, Popconfirm } from 'antd';
import dayjs from 'dayjs'; // Pastikan dayjs telah diimpor dengan benar
import CreateAxiosInstance from '../../../Authentication/contexts/axiosConfig';

const EditDataLahan = ( { onClose, fetchData, recordId } ) =>
{
    const axiosConfig = CreateAxiosInstance();
    const [ form ] = Form.useForm();
    const [ loading, setLoading ] = useState( false );
    const [ plotCode, setPlotCode ] = useState();
    const [ dataLahan, setDataLahan ] = useState( [] );
    useEffect( () =>
    {
        const fetchListLahan = async () =>
        {
            setLoading( true );
            try
            {
                const response = await axiosConfig.get( `${ process.env.REACT_APP_LOCAL_API }/lahan/list/data/${ recordId }` );
                const data = response.data;
                setDataLahan( data );
                form.setFieldsValue( {
                    ...data,
                    farmerName: data.farmerName,
                    surfaceArea: data.surfaceArea,
                    landCode: parseFloat( data.landCode.replace( /[^\d]/g, '' ) ),
                    timePeriod: data.timePeriod,
                    landPrice: data.landPrice,
                    landLocation: data.landLocation,
                    bookingValue: data.bookingValue,
                    bookingDate: dayjs( data.bookingDate, 'DD/MM/YYYY' ),
                    seriousPaySign: data.seriousPaySign,
                    seriousDateSign: dayjs( data.seriousDateSign, 'DD/MM/YYYY' ),
                } );
            } catch ( error )
            {
                onClose();
                message.error( 'Gagal memuat daftar lahan' );
            } finally
            {
                setLoading( false );
            }
        };
        if ( recordId )
        {
            fetchListLahan();
        }
    }, [ recordId, form, onClose ] );

    const handleLokasiLahan = ( value ) =>
    {
        setPlotCode( value );
    };
    const onFinish = async ( value ) =>
    {
        const newData = {
            ...value,
            farmerName: value.farmerName,
            surfaceArea: value.surfaceArea,
            landCode: value.landLocation + value.landCode,
            timePeriod: value.timePeriod,
            landPrice: value.landPrice,
            landLocation: value.landLocation,
            bookingValue: value.bookingValue,
            bookingDate: dayjs( value.bookingDate ).format( 'DD/MM/YYYY' ),
            seriousPaySign: value.seriousPaySign,
            seriousDateSign: dayjs( value.seriousDateSign ).format( 'DD/MM/YYYY' ),
        };
        try
        {
            setLoading( true );
            await axiosConfig.put( `${ process.env.REACT_APP_LOCAL_API }/lahan/list/data/${ recordId }`, newData );
            message.success( 'Daftar lahan baru berhasil diperbarui' );
            form.resetFields();
            onClose();
            fetchData();
        } catch ( error )
        {
            if ( error.response && error.response.status === 400 )
            {
                message.error( error.response.data.message );
            } else
            {
                message.error( 'Gagal memperbarui daftar lahan baru' );
            }
        } finally
        {
            setLoading( false );
        }
    };

    return (
        <Spin spinning={ loading }>
            <Form form={ form } onFinish={ onFinish } layout="vertical">
                <div className='payments-flex'>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="farmerName"
                        label="Nama Petani"
                        rules={ [ { required: true, message: 'Nama petani diperlukan!' } ] }
                    >
                        <Input placeholder="Masukkan Nama" />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="landLocation"
                        label="Lokasi Lahan"
                        rules={ [ { required: true, message: 'Lokasi lahan diperlukan!' } ] }
                    >
                        <Select
                            style={ { width: '100%' } }
                            placeholder="Pilih Lokasi"
                            onChange={ handleLokasiLahan }
                            options={ [
                                { value: 'PETAL', label: 'PETAL' },
                                { value: 'DOMAS', label: 'DOMAS' },
                                { value: 'TANJUNG', label: 'TANJUNG' },
                            ] }
                        />
                    </Form.Item>
                </div>
                <div className='payments-flex'>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="landCode"
                        label="Kode Lahan"
                        rules={ [ { required: true, message: 'Kode lahan diperlukan!' } ] }
                    >
                        <InputNumber
                            maxLength={ 1 }
                            style={ { width: '100%' } }
                            placeholder="Masukkan Kode Lahan" />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="landPrice"
                        label="Harga Lahan"
                    >
                        <InputNumber
                            addonBefore='Rp'
                            style={ { width: '100%' } }
                            placeholder="Masukkan Harga" />
                    </Form.Item>
                </div>
                <div className='payments-flex'>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="timePeriod"
                        label="Tenor Lahan"
                    >
                        <InputNumber
                            addonAfter='Bulan'
                            style={ { width: '100%' } }
                            placeholder="Masukkan Tenor" />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="surfaceArea"
                        label="Luas Lahan"
                    >
                        <InputNumber
                            style={ { width: '100%' } }
                            placeholder="Masukkan Luas Lahan"
                            addonAfter='M²' />
                    </Form.Item>
                </div>
                <div className='payments-flex'>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="seriousPaySign"
                        label="Jumlah Tanda Serius"
                    >
                        <InputNumber
                            addonBefore='Rp'
                            style={ { width: '100%' } }
                            placeholder="Jumlah Tanda Serius" />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="bookingValue"
                        label="Jumlah Tanda Jadi"
                    >
                        <InputNumber
                            addonBefore='Rp'
                            style={ { width: '100%' } }
                            placeholder="Jumlah Tanda Jadi" />
                    </Form.Item>
                </div>
                <div className='payments-flex'>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="seriousDateSign"
                        label="Tanggal Tanda Serius"
                    >
                        <DatePicker
                            format='DD/MM/YYYY'
                            style={ { width: '100%' } }
                            placeholder="Pilih Tanggal" />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="bookingDate"
                        label="Tanggal Tanda Jadi"
                    >
                        <DatePicker
                            format='DD/MM/YYYY'
                            style={ { width: '100%' } }
                            placeholder="Pilih Tanggal" />
                    </Form.Item>
                </div>
                <Popconfirm
                    title="Apakah kamu yakin melakukan update data ini?"
                    onConfirm={ () => form.submit() }
                    okText="Yes"
                    cancelText="No"
                >
                    <Button type='primary' style={ { marginRight: 5 } }>
                        Update Lahan
                    </Button>
                </Popconfirm>
            </Form>
        </Spin>
    );
};

export default EditDataLahan;
