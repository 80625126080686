import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { Button, message, Table, Input, InputNumber, Modal } from 'antd';
import { v4 as uuidv4 } from 'uuid'; // Import UUID generator
import { CiSearch } from "react-icons/ci";
import { useNavigate } from 'react-router';
import CreateAxiosInstance from '../../../Authentication/contexts/axiosConfig';
dayjs.extend( isSameOrBefore );

const BillingData = () =>
{
    const apiUrl = process.env.REACT_APP_LOCAL_API;
    const axiosConfig = CreateAxiosInstance();
    const [ loading, setLoading ] = useState( false );
    const [ database, setDatabase ] = useState( [] );
    const [ customerData, setCustomerData ] = useState( [] );
    const [ searchName, setSearchName ] = useState( '' );
    const [ paided, setPaided ] = useState( 0 );
    const [ notPaided, setNotPaided ] = useState( 0 );
    const navigate = useNavigate();
    useEffect( () =>
    {
        navigate( '/underdevelopment-page' );
    }, [] );
    const fetchData = async () =>
    {
        setLoading( true );
        try
        {
            const response = await axiosConfig.get( `${ apiUrl }/customer/data` );
            setDatabase( response.data );
        } catch ( error )
        {
            console.error( error );
        } finally
        {
            setLoading( false );
        }
    };

    useEffect( () =>
    {
        fetchData();
    }, [] );

    const convertToIDR = ( value ) =>
    {
        return new Intl.NumberFormat( 'id-ID', {
            style: 'currency',
            currency: 'IDR',
            maximumFractionDigits: 0,
        } ).format( value );
    };

    useEffect( () =>
    {
        const filterData = database.map( db =>
        {
            const defaultDate = dayjs().format( 'DD/MM/YYYY' );
            const paymentData = db.pembayaranAngsuran || [];
            const firstPayment = paymentData.length > 0 ? paymentData[ 0 ] : null;
            const lastPayment = paymentData.length > 0 ? paymentData[ paymentData.length - 1 ] : null;
            const filteredPaymentData = paymentData.filter( item =>
                dayjs( item.tanggalByrAngs2 || item.tanggalByrAngs, 'DD/MM/YYYY' ).isSame( dayjs(), 'month' )
            );
            const angsuran = db.batasAngsuran2
                ? dayjs( defaultDate, 'DD/MM/YYYY' ).isSameOrBefore( dayjs( db.batasAngsuran2, 'DD/MM/YYYY' ) )
                    ? db.angsuranKavling
                    : db.angsuranKavling2
                : db.angsuranKavling;

            const underPayment = db.batasAngsuran2
                ? ( () =>
                {
                    const dataAngsuranBefore = paymentData.filter( f =>
                        dayjs( f.tanggalByrAngs2 || f.tanggalByrAngs, 'DD/MM/YYYY' ).isSameOrBefore( dayjs( db.batasAngsuran2, 'DD/MM/YYYY' ), 'month' ) &&
                        f.jumlahByrAngs < db.angsuranKavling
                    ).map( d => ( {
                        belumTerbayar: db.angsuranKavling - d.jumlahByrAngs,
                    } ) );

                    const dataAngsuranAfter = paymentData.filter( f =>
                        dayjs( f.tanggalByrAngs2 || f.tanggalByrAngs, 'DD/MM/YYYY' ).isAfter( dayjs( db.batasAngsuran2, 'DD/MM/YYYY' ), 'month' ) &&
                        f.jumlahByrAngs < db.angsuranKavling2
                    ).map( d => ( {
                        belumTerbayar: db.angsuranKavling2 - d.jumlahByrAngs,
                    } ) );

                    const dataAngsuran = [ ...dataAngsuranBefore, ...dataAngsuranAfter ];
                    const totalSisa = dataAngsuran.reduce( ( total, item ) => total + item.belumTerbayar, 0 );

                    return { totalSisa };
                } )()
                : ( () =>
                {
                    const dataAngsuran = paymentData.filter( f =>
                        f.jumlahByrAngs < db.angsuranKavling
                    ).map( d => ( {
                        belumTerbayar: db.angsuranKavling - d.jumlahByrAngs,
                    } ) );
                    const totalSisa = dataAngsuran.reduce( ( total, item ) => total + item.belumTerbayar, 0 );

                    return { totalSisa };
                } )();
            const kavlingLocation = database.some( location => location.lokasiKavling === 'DOMAS' )
                ? 'Domas, Menganti'
                : database.some( location => location.lokasiKavling === 'PETAL' )
                    ? 'Petal, Menganti'
                    : 'Tanjung, Kedamean';
            return {
                customerLocation: kavlingLocation,
                customerBlock: ( db.blokKavling === 'TANPABLOK' ? db.noKavling : db.blokKavling + db.noKavling ),
                customerId: db._id,
                customerName: db.namaCustomer,
                angsuranCustomer: angsuran,
                customerNumber: db.telpCustomer,
                lastPaymentTo: lastPayment
                    ? lastPayment.angsKe
                        ? lastPayment.angsKe + 1
                        : null
                    : null,
                lastPaymentDate: lastPayment ? ( lastPayment.tanggalByrAngs || lastPayment.tanggalByrAngs2 ) : null,
                blok: ( db.blokKavling === 'TANPABLOK' ? db.noKavling : db.blokKavling + db.noKavling ),
                firstPayment: firstPayment ? ( firstPayment.tanggalByrAngs || firstPayment.tanggalByrAngs2 ) : null,
                paymentValue: filteredPaymentData.length === 1
                    ? filteredPaymentData[ 0 ].jumlahByrAngs
                    : filteredPaymentData.length > 1
                        ? filteredPaymentData.reduce( ( total, item ) => total + ( item.jumlahByrAngs ), 0 )
                        : 0,
                underPayment: underPayment.totalSisa > 0
                    ? convertToIDR( underPayment.totalSisa )
                    : 'LUNAS',
                penaltyCustomer: db.dendaCustomer,
            };
        } ).filter( ft =>
            dayjs( ft.firstPayment, 'DD/MM/YYYY' ).isSameOrBefore( dayjs().endOf( 'month' ) )
        );
        const telahBayar = filterData.filter( item => item.paymentValue === item.angsuranCustomer );
        const belumBayar = filterData.filter( item => item.paymentValue < item.angsuranCustomer );
        setPaided( telahBayar.length );
        setNotPaided( belumBayar.length );
        setCustomerData( filterData );
    }, [ database ] );
    const sendWhatsAppMessage = ( customerNumber, messageText ) =>
    {
        const encodedMessage = encodeURIComponent( messageText );
        const fullCustomerNumber = `62${ customerNumber }`;
        const whatsappURL = `https://wa.me/${ fullCustomerNumber }?text=${ encodedMessage }`;
        window.open( whatsappURL, '_blank' );
    };
    const handleSendBilling = async ( recordId ) =>
    {
        const uniqueCode = uuidv4();
        const customer = customerData.find( cs => cs.customerId === recordId );
        const dueDate = dayjs( customer.lastPaymentDate, 'DD/MM/YYYY' ).add( 1, 'month' ).format( 'DD/MM/YYYY' );
        const whatsappMessage = `Selamat pagi pak/bu ${ customer.customerName },\nIni adalah sebuah pesan pengingat pembayaran angsuran otomatis.\n\nNama : ${ customer.customerName }\nKavling :${ customer.customerBlock }\nLokasi : ${ customer.customerLocation }\n\nIni adalah link detail tagihannya \nhttp://localhost:3000/payment-billing/${ uniqueCode }`;

        setLoading( true );
        try
        {
            await axiosConfig.post( `${ apiUrl }/create-billing`, {
                customerId: recordId,
                customerName: customer.customerName,
                amount: customer.angsuranCustomer,
                customerBlock: customer.customerBlock,
                paymentTo: customer.lastPaymentTo,
                dueDate: dueDate,
                uniqueCode: uniqueCode,
                underPayment: customer.underPayment,
                penaltyCustomer: customer.penaltyCustomer,
            } );
            sendWhatsAppMessage( customer.customerNumber, whatsappMessage );
        } catch ( error )
        {
            console.error( error );
            message.error( 'Gagal membuat tagihan!' );
        } finally
        {
            setLoading( false );
        }
    };
    const handleSearch = ( e ) =>
    {
        const { value } = e.target;
        setSearchName( value );
    };
    const filteredCustomerData = customerData.filter( customer =>
        customer.customerName.toLowerCase().includes( searchName.toLowerCase() )
    );
    const billingColumns = [
        {
            title: 'Block',
            dataIndex: 'blok',
            width: '8%',
            align: 'center',
        },
        {
            title: <p style={ { textAlign: 'center' } }>Nama Customer</p>,
            dataIndex: 'customerName',
            align: 'left',
            width: 'auto',
        },
        {
            title: 'Angsuran Bulan Ini',
            dataIndex: 'angsuranCustomer',
            width: 'auto',
            align: 'center',
            render: ( value, record ) => record.paymentValue >= value
                ? 'LUNAS'
                : record.paymentValue > 0 && record.paymentValue < value
                    ? convertToIDR( value - ( record.paymentValue || 0 ) )
                    : convertToIDR( value )
        },
        {
            title: 'Sisa Angsuran Sebelumnya',
            dataIndex: 'underPayment',
            width: 'auto',
            align: 'center',
        },
        {
            title: 'Aksi',
            width: 'auto',
            align: 'center',
            render: ( value ) => (
                <div style={ { display: 'flex', gap: 5, justifyContent: 'center' } }>
                    <Button
                        type='default'
                        onClick={ () => handleSendBilling( value.customerId ) }
                        disabled={ value.paymentValue >= value.angsuranCustomer }
                    >
                        Tagih
                    </Button>
                    <Button
                        type='default'
                        onClick={ () => handleCheckBilling( value.customerId ) }
                        disabled={ value.paymentValue >= value.angsuranCustomer }>
                        Cek
                    </Button>
                </div>
            )
        },
    ];
    const handleCheckBilling = ( recordId ) =>
    {
        const customer = customerData.find( cs => cs.customerId === recordId );
        Modal.info( {
            title: 'Detail Tagihan',
            content: (
                <>
                    <p>Nama : { customer.customerName }</p>
                    <p>Blok : { customer.customerBlock }</p>
                    <p>Jumlah Angsuran : { convertToIDR( customer.angsuranCustomer ) }</p>
                    <p>Angsuran Ke : { customer.lastPaymentTo }</p>
                    { customer.underPayment > 0 ? (
                        <p>Sisa Angs. Sebelumnya : { convertToIDR( customer.underPayment ) }</p>
                    ) : null }
                    { customer.penaltyCustomer ? (
                        <p>Denda : { convertToIDR( customer.penaltyCustomer ) }</p>
                    ) : null }
                </>
            ),
        } )
    }
    return (
        <div style={ { margin: '0 5px' } }>
            <div style={ { display: 'flex', gap: 5, margin: '10px 0' } }>
                <Input
                    addonBefore={ <CiSearch /> }
                    placeholder="Masukkan nama customer"
                    value={ searchName }
                    onChange={ handleSearch }
                />
                <InputNumber
                    addonBefore='Sudah Bayar'
                    value={ paided }
                    addonAfter='Pelanggan'
                    style={ { width: '100%' } }
                    readOnly />
                <InputNumber
                    addonBefore='Belum Bayar'
                    value={ notPaided }
                    addonAfter='Pelanggan'
                    style={ { width: '100%' } }
                    readOnly />
            </div>
            <Table
                dataSource={ filteredCustomerData }
                columns={ billingColumns }
                loading={ loading }
                rowKey="customerId"
                size='small'
                scroll={ { y: 380 } }
            />
        </div>
    );
}

export default BillingData;
