import React, { useEffect, useState } from 'react';
import { Button, Drawer, Table, message, DatePicker, InputNumber } from 'antd';
import dayjs from 'dayjs';
import { CiEdit } from 'react-icons/ci';
import { SlRefresh } from "react-icons/sl";
import 'dayjs/locale/id';
import EditAdditionalIncome from './editPemasukanTambahan';
import AddAdditionalIncome from './tambahPemasukan';
import CreateAxiosInstance from '../../../Authentication/contexts/axiosConfig';
dayjs.locale( 'id' );
const IncomeAdditionalData = () =>
{
    const axiosConfig = CreateAxiosInstance();
    const [ data, setData ] = useState( [] );
    const [ loading, setLoading ] = useState( false );
    const [ drawerEditPTT, setDrawerEditPTT ] = useState( false );
    const [ editRecordId, setEditRecordId ] = useState( null );
    const [ drawerTambahPTT, setDrawerTambahPTT ] = useState( false );
    const [ selectedMonth, setSelectedMonth ] = useState( dayjs() );
    const [ totalPemasukan, setTotalPemasukan ] = useState();
    const fetchData = async () =>
    {
        setLoading( true );
        try
        {
            const response = await axiosConfig.get( `${ process.env.REACT_APP_LOCAL_API }/additionalIncome/data` );
            const updatedData = response.data;
            setData( updatedData );
        } catch ( error )
        {
            message.error( 'Gagal mengambil data dari server' );
        } finally
        {
            setLoading( false );
        }
    };
    const convertToIDR = value =>
    {
        return new Intl.NumberFormat( 'id-ID', {
            style: 'currency',
            currency: 'IDR',
            maximumFractionDigits: 0
        } ).format( value );
    };
    useEffect( () =>
    {
        fetchData();
    }, [] );
    const handleMonthChange = ( date ) =>
    {
        setSelectedMonth( date );
    };
    const filteredData = data.filter( ( item ) =>
    {
        if ( selectedMonth )
        {
            return dayjs( item.dateGet, 'DD/MM/YYYY' ).isSame( selectedMonth, 'month' );
        } else
        {
            return true;
        }
    } );
    useEffect( () =>
    {
        if ( selectedMonth )
        {
            const totalPemasukanBulanan = filteredData.reduce( ( total, item ) =>
            {
                return total + item.howMuch;
            }, 0 );
            setTotalPemasukan( totalPemasukanBulanan );
        } else
        {
            const totalSemuaPengeluaran = data.reduce( ( total, item ) =>
            {
                return total + item.howMuch;
            }, 0 );
            setTotalPemasukan( totalSemuaPengeluaran );
        }
    }, [ filteredData, selectedMonth, data ] );
    const columns = [
        {
            title: 'Aksi',
            key: 'action',
            width: '7%',
            align: 'center',
            render: ( text, record ) => (
                <Button type='default' onClick={ () => handleEdit( record._id ) } icon={ <CiEdit /> } />
            ),
        },
        {
            title: <p style={ { textAlign: 'center' } }>Catatan</p>,
            dataIndex: 'ofWhom',
            align: 'left',
            width: '20vw',
        },
        {
            title: 'Harga',
            dataIndex: 'howMuch',
            width: 'auto',
            align: 'center',
            render: value => convertToIDR( value )
        },
        {
            title: 'Tanggal',
            dataIndex: 'dateGet',
            width: 'auto',
            align: 'center',
        },
        {
            title: 'Metode',
            dataIndex: 'incomeMethod',
            width: 'auto',
            align: 'center',
        },
    ];
    const handleCloseBayarGaji = () =>
    {
        setDrawerTambahPTT( false );
    };
    const handleOpenBayarGaji = () =>
    {
        setDrawerTambahPTT( true );
    };
    const handleEdit = recordId =>
    {
        setEditRecordId( recordId );
        setDrawerEditPTT( true );
    };
    const handleCloseEditGaji = () =>
    {
        setDrawerEditPTT( false );
        setEditRecordId( null );
    };
    const handleRefresh = () =>
    {
        fetchData();
    };
    return (
        <div >
            <div style={ { padding: '0 5px' } }>
                <div style={ { display: 'flex', gap: 5, padding: '10px 0' } }>
                    <Button style={ { padding: 5 } } onClick={ handleRefresh } icon={ <SlRefresh /> } />
                    <Button onClick={ handleOpenBayarGaji }>
                        Tambah Pemasukan
                    </Button>
                    <InputNumber
                        readOnly
                        style={ { width: '100%' } }
                        value={ totalPemasukan >= 1 && convertToIDR( totalPemasukan ) }
                        placeholder={ totalPemasukan < 1 && 'Tidak ada pengeluaran lain' }
                        addonBefore={ totalPemasukan >= 1 && 'Total' } />
                    <DatePicker.MonthPicker
                        style={ { width: '30%' } }
                        onChange={ handleMonthChange }
                        value={ selectedMonth }
                        picker="month"
                        format={ ( value ) => dayjs( value ).format( 'MMMM YYYY' ) }
                        placeholder='Pilih Bulan'
                    />
                </div>
                <Table
                    bordered
                    columns={ columns }
                    showSorterTooltip={ false }
                    dataSource={ filteredData }
                    loading={ loading }
                    rowKey="_id"
                    size="small"
                    scroll={ { y: 450 } }
                />
            </div>
            <div>
                <Drawer
                    title="Edit Gaji"
                    placement="right"
                    closable={ true }
                    onClose={ handleCloseEditGaji }
                    visible={ drawerEditPTT }
                    width="50%"
                >
                    <EditAdditionalIncome
                        key={ `edit-form-key-${ editRecordId }` }
                        recordId={ editRecordId }
                        onClose={ handleCloseEditGaji }
                        fetchData={ fetchData }
                    />
                </Drawer>
                <Drawer
                    title="Pengeluaran Tak Terduga"
                    placement="right"
                    closable={ true }
                    onClose={ handleCloseBayarGaji }
                    visible={ drawerTambahPTT }
                    width="40%"
                >
                    <AddAdditionalIncome onClose={ handleCloseBayarGaji } fetchData={ fetchData } />
                </Drawer>
            </div>
        </div>
    );
};
export default IncomeAdditionalData;
