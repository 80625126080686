import React, { useState, useEffect } from 'react';
import { Form, Button, message, Spin, Input, Select, InputNumber, DatePicker, Popconfirm } from 'antd';
import dayjs from 'dayjs';
import CreateAxiosInstance from '../../../../Authentication/contexts/axiosConfig';
const EditLoan = ( { onClose, fetchData, recordId, loanId } ) =>
{
    const axiosConfig = CreateAxiosInstance();
    const [ form ] = Form.useForm();
    const [ loading, setLoading ] = useState( false );
    useEffect( () =>
    {
        const fetchListData = async () =>
        {
            setLoading( true );
            try
            {
                const response = await axiosConfig.get( `${ process.env.REACT_APP_LOCAL_API }/loan/list/${ recordId }` )
                const data1 = response.data;
                const data2 = data1.riwayatPinjaman.find( db => db._id === loanId )
                form.setFieldsValue( {
                    ...data2,
                    namaNasabah: data1.namaNasabah,
                    tanggalPinjaman: dayjs( data2.loanDate, 'DD/MM/YYYY' ),
                    nominalPinjaman: data2.loanValue,
                    metodePinjaman: data2.loanMethod,
                    keperluanPinjaman: data2.loanDescription,
                    jaminanPinjaman: data2.loanAssurance,
                } )
            } catch ( error )
            {
                onClose();
                message.error( 'Gagal mengambil data dari server' );
            } finally
            {
                setLoading( false );
            }
        };
        if ( recordId )
        {
            fetchListData();
        }
    }, [ recordId, loanId, form, onClose ] );
    const handleDelete = async () =>
    {
        try
        {
            setLoading( true );
            await axiosConfig.delete( `${ process.env.REACT_APP_LOCAL_API }/loan/list/${ recordId }{ loanId }` );
            message.success( 'Berhasil menghapus pinjaman' );
            fetchData()
            onClose()
        } catch ( error )
        {
            message.error( 'Terjadi kesalahan saat menghapus pinjaman' );
        } finally
        {
            setLoading( false );
        }
    }
    const onFinish = async ( values ) =>
    {
        const updatePinjaman = {
            ...values,
            loanValue: values.nominalPinjaman,
            loanDate: dayjs( values.tanggalPinjaman ).format( 'DD/MM/YYYY' ),
            loanMethod: values.metodePinjaman,
            loanDescription: values.keperluanPinjaman,
            loanAssurance: values.jaminanPinjaman,
        };
        try
        {
            setLoading( true );
            await axiosConfig.put( `${ process.env.REACT_APP_LOCAL_API }/loan/list/${ recordId }/loan/${ loanId }`, updatePinjaman );
            message.success( 'Pinjaman baru berhasil ditambahkan' );
            form.resetFields();
            onClose();
            fetchData();
        } catch ( error )
        {
            message.error( 'Pinjaman baru gagal ditambahkan' );
        } finally
        {
            setLoading( false );
        }
    }

    return (
        <Spin spinning={ loading }>
            <Form form={ form } onFinish={ onFinish } layout="vertical">
                <div className="payments-flex">
                    <Form.Item
                        style={ { width: '100%' } }
                        label="Nama Nasabah"
                        name="namaNasabah"
                        rules={ [ { required: true, message: 'Pilihan diperlukan!' } ] }
                    >
                        <Input readOnly />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name='tanggalPinjaman'
                        label='Tanggal Pinjaman'
                        rules={ [ { required: true, message: 'Tanggal pinjaman diperlukan!' } ] }
                    >
                        <DatePicker
                            style={ { width: '100%' } }
                            placeholder='Pilih Tanggal Pinjaman'
                            format='DD/MM/YYYY' />
                    </Form.Item>
                </div>
                <div className="payments-flex">
                    <Form.Item
                        style={ { width: '100%' } }
                        name='nominalPinjaman'
                        label='Jumlah Pinjaman'
                        rules={ [ { required: true, message: 'Jumlah pinjaman diperlukan!' } ] }
                    >
                        <InputNumber
                            style={ { width: '100%' } }
                            addonBefore='Rp'
                            min={ 1 }
                            placeholder='Masukkan Jumlah Pinjaman' />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name='metodePinjaman'
                        label='Metode Pinjaman'
                        rules={ [ { required: true, message: 'Jumlah pinjaman diperlukan!' } ] }
                    >
                        <Select
                            style={ { width: '100%' } }
                            min={ 1 }
                            placeholder='Pilih Metode Pinjaman'
                            options={ [
                                { value: 'TUNAI', label: 'TUNAI' },
                                { value: 'BRI', label: 'BRI' },
                                { value: 'BNI', label: 'BNI' },
                            ] } />
                    </Form.Item>
                </div>
                <div className="payments-flex">
                    <Form.Item
                        style={ { width: '100%' } }
                        name='jaminanPinjaman'
                        label='Jaminan Pinjaman'
                        tooltip='Tidak Wajib'
                    >
                        <Input
                            addonBefore='Rp'
                            style={ { width: '100%' } }
                            placeholder='Masukkan Jaminan Pinjaman' />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name='keperluanPinjaman'
                        label='Keperluan Pinjaman'
                        tooltip='Tidak Wajib'
                    >
                        <Input
                            style={ { width: '100%' } }
                            placeholder='Masukkan Keperluan Pinjaman' />
                    </Form.Item>
                </div>
                <div style={ { display: 'flex', gap: 5 } }>
                    <Popconfirm
                        title="Apakah kamu yakin melakukan update data ini ?"
                        onConfirm={ () => form.submit() }
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type='primary' loading={ loading }  >
                            Update
                        </Button>
                    </Popconfirm>
                    <Popconfirm
                        title="Apakah kamu yakin menghapus data ini ?"
                        onConfirm={ handleDelete }
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type='default' danger loading={ loading }  >
                            Hapus
                        </Button>
                    </Popconfirm>
                </div>
            </Form>
        </Spin >
    );
};

export default EditLoan;
