import { Descriptions, message, Typography, List, DatePicker } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import dayjs from 'dayjs';
import 'dayjs/locale/id';
import CreateAxiosInstance from "../../../Authentication/contexts/axiosConfig";
dayjs.locale( 'id' );

const DataLahanCek = ( { recordId } ) =>
{
    const axiosConfig = CreateAxiosInstance();
    const [ dataLahan, setDataLahan ] = useState( null );
    const [ pembayaran, setPembayaran ] = useState( [] );
    const [ paymentYearData, setPaymentYearData ] = useState( [] );
    const [ selectedYear, setSelectedYear ] = useState( dayjs() );
    useEffect( () =>
    {
        const fetchDataById = async () =>
        {
            try
            {
                const response = await axiosConfig.get( `${ process.env.REACT_APP_LOCAL_API }/lahan/list/data/${ recordId }` );
                const data = response.data;

                const updatedData = {
                    farmerName: data.farmerName,
                    surfaceArea: data.surfaceArea,
                    landCode: data.landCode,
                    timePeriod: data.timePeriod,
                    landPrice: data.landPrice,
                    landLocation: data.landLocation,
                    seriousPaySign: data.seriousPaySign,
                    seriousDateSign: dayjs( data.seriousDateSign, 'DD/MM/YYYY' ),
                    amountPaid: data.landPayment.reduce( ( total, item ) => total + ( item.jumlahByrLhn || 0 ), 0 ),
                    remainingPayment: data.landPrice - data.landPayment.reduce( ( total, item ) => total + ( item.jumlahByrLhn || 0 ), 0 ),
                    amountCommissionPaid: data.landPayment.reduce( ( total, item ) => total + ( item.kompensasiLhn || 0 ), 0 )
                };

                setDataLahan( updatedData );
                setPembayaran( data.landPayment );
            } catch ( error )
            {
                onclose();
                message.error( "Gagal mengambil data dari server" );
            }
        };
        if ( recordId )
        {
            fetchDataById();
        }
    }, [ recordId ] );
    const handleYearChange = ( date ) =>
    {

        setSelectedYear( date )

    };
    useEffect( () =>
    {
        const paymentData = pembayaran.filter( ft =>
            dayjs( ft.tanggalByrLhn, 'DD/MM/YYYY' ).isSame( selectedYear, 'year' )
        )
        setPaymentYearData( paymentData )
    }, [ selectedYear, pembayaran ] );
    const convertToIDR = ( value ) =>
    {
        return new Intl.NumberFormat( 'id-ID', {
            style: 'currency',
            currency: 'IDR',
            maximumFractionDigits: 0
        } ).format( value );
    };

    if ( !dataLahan )
    {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <Descriptions title={ `Data Lahan ${ dataLahan.farmerName }` } column={ 2 }>
                <Descriptions.Item label="Harga Lahan">{ convertToIDR( dataLahan.landPrice ) }</Descriptions.Item>
                <Descriptions.Item label="Luas Lahan">{ dataLahan.surfaceArea }</Descriptions.Item>
                <Descriptions.Item label="Lokasi Lahan">{ dataLahan.landLocation }</Descriptions.Item>
                <Descriptions.Item label="Tanggal Bayar">{ dayjs( dataLahan.seriousDateSign, 'DD/MM/YYYY' ).format( 'DD MMMM YYYY' ) }</Descriptions.Item>
                <Descriptions.Item label="Kode Lahan">{ dataLahan.landCode }</Descriptions.Item>
                <Descriptions.Item label="Tanda Serius">{ convertToIDR( dataLahan.seriousPaySign ) }</Descriptions.Item>
                <Descriptions.Item label="Sudah Terbayar">{ convertToIDR( dataLahan.amountPaid ) }</Descriptions.Item>
                <Descriptions.Item label="Tenor Lahan">{ dataLahan.timePeriod }</Descriptions.Item>
                <Descriptions.Item label="Belum Terbayar">{ convertToIDR( dataLahan.remainingPayment ) }</Descriptions.Item>
                <Descriptions.Item label="Total Kompensasi">{ convertToIDR( dataLahan.amountCommissionPaid ) }</Descriptions.Item>
            </Descriptions>
            <DatePicker.YearPicker
                placeholder="Pilih Tahun Pembayaran"
                onChange={ handleYearChange }
                style={ { width: '100%', marginBottom: 10 } } />
            <List
                bordered
                dataSource={ paymentYearData }
                header='Riwayat Pembayaran'
                renderItem={ ( item ) => (
                    <List.Item>
                        <div>
                            { item.tanggalByrLhn ? (
                                <>
                                    <Typography.Text strong>Tanggal Pembayaran Lahan: </Typography.Text>{ dayjs( item.tanggalByrLhn, 'DD/MM/YYYY' ).format( 'DD MMMM YYYY' ) }<br />
                                </>
                            ) : null }
                            { item.jumlahByrLhn ? (
                                <>
                                    <Typography.Text strong>Jumlah Pembayaran Lahan: </Typography.Text>{ convertToIDR( item.jumlahByrLhn ) }<br />
                                </>
                            ) : null }
                            { item.metodeByrLhn ? (
                                <>
                                    <Typography.Text strong>Metode Pembayaran Lahan: </Typography.Text>{ item.metodeByrLhn }<br />
                                </>
                            ) : null }
                            { item.bayarLhnKe ? (
                                <>
                                    <Typography.Text strong>Bayar Lahan Ke: </Typography.Text>{ item.bayarLhnKe }<br />
                                </>
                            ) : null }
                            { item.kompensasiLhn ? (
                                <>
                                    <Typography.Text>Kompensasi Pembayaran Lahan: </Typography.Text>{ convertToIDR( item.kompensasiLhn ) }<br />
                                </>
                            ) : null }
                        </div>
                    </List.Item>
                ) }
            />
        </div>
    );
};

export default DataLahanCek;
