import { Button, Descriptions, message, Popconfirm, Spin, Table } from 'antd';
import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs'
import 'dayjs/locale/id';
import CreateAxiosInstance from '../../../Authentication/contexts/axiosConfig';
dayjs.locale( 'id' );

const DataGajiCek = ( { recordId, fetchData, onClose } ) =>
{
    const axiosConfig = CreateAxiosInstance();
    const [ dataGaji, setDataGaji ] = useState( {} );
    const [ dataGajiHarian, setDataGajiHarian ] = useState( [] );
    const [ dataGajiLembur, setDataGajiLembur ] = useState( [] );
    const [ loading, setLoading ] = useState( false );

    const listDataGaji = async () =>
    {
        setLoading( true );
        try
        {
            const response = await axiosConfig.get( `${ process.env.REACT_APP_LOCAL_API }/salaryMan/data/${ recordId }` );
            const data = response.data;
            setDataGaji( data );
            setDataGajiHarian( data.gajiHarian );
            setDataGajiLembur( data.gajiLembur );
        } catch ( error )
        {
            message.error( 'Gagal memuat data pekerja' );
        } finally
        {
            setLoading( false );
        }
    };

    useEffect( () =>
    {
        if ( recordId )
        {
            listDataGaji();
        }
    }, [ recordId ] );
    const handleDelete = async () =>
    {
        try
        {
            setLoading( true );
            await axiosConfig.delete( `${ process.env.REACT_APP_LOCAL_API }/salaryMan / data / ${ recordId }` );
            message.success( 'Berhasil menghapus data gaji tukang' );
            onClose();
            fetchData();
        } catch ( error )
        {
            message.error( 'Gagal menghapus gaji tukang', error );
        } finally
        {
            setLoading( false );
        }
    }
    const columns = [
        {
            title: <p style={ { textAlign: 'center' } }>Nama Pekerja</p>,
            dataIndex: 'nama',
            align: 'left',
            width: 'auto',
        },
        {
            title: 'Senin',
            dataIndex: 'senin',
            align: 'center',
            width: 'auto',
            render: value => value ? convertToIDR( value ) : <p style={ { color: 'red' } }>Libur</p>,
        },
        {
            title: 'Selasa',
            dataIndex: 'selasa',
            align: 'center',
            width: 'auto',
            render: value => value ? convertToIDR( value ) : <p style={ { color: 'red' } }>Libur</p>,
        },
        {
            title: 'Rabu',
            dataIndex: 'rabu',
            align: 'center',
            width: 'auto',
            render: value => value ? convertToIDR( value ) : <p style={ { color: 'red' } }>Libur</p>,
        },
        {
            title: 'Kamis',
            dataIndex: 'kamis',
            align: 'center',
            width: 'auto',
            render: value => value ? convertToIDR( value ) : <p style={ { color: 'red' } }>Libur</p>,
        },
        {
            title: 'Jumat',
            dataIndex: 'jumat',
            align: 'center',
            width: 'auto',
            render: value => value ? convertToIDR( value ) : <p style={ { color: 'red' } }>Libur</p>,
        },
        {
            title: 'Sabtu',
            dataIndex: 'sabtu',
            align: 'center',
            width: 'auto',
            render: value => value ? convertToIDR( value ) : <p style={ { color: 'red' } }>Libur</p>,
        },
        {
            title: 'Minggu',
            dataIndex: 'minggu',
            align: 'center',
            width: 'auto',
            render: value => value ? convertToIDR( value ) : <p style={ { color: 'red' } }>Libur</p>,
        },
    ];
    const convertToIDR = ( value ) =>
    {
        return new Intl.NumberFormat( 'id-ID', {
            style: 'currency',
            currency: 'IDR',
        } ).format( value );
    };
    return (
        <Spin spinning={ loading }>
            <Descriptions title={ `Data Gaji ${ dayjs( dataGaji.tanggalGaji, 'DD/MM/YYYY' ).format( 'dddd, DD MMMM YYYY' )
                }` }>
                <Descriptions.Item label="Total Gaji Mingguan">{ convertToIDR( dataGaji.totalGajiMingguan ) }</Descriptions.Item>
                <Descriptions.Item label="Total Gaji Lembur">{ convertToIDR( dataGaji.totalGajiLembur ) }</Descriptions.Item>
                <Descriptions.Item label="Total Pembayaran Gaji">{ convertToIDR( dataGaji.totalPembayaranGaji ) }</Descriptions.Item>
                <Descriptions.Item label="Uang Makan Tukang">{ convertToIDR( dataGaji.uangMakanTukang || 0 ) }</Descriptions.Item>
                <Descriptions.Item label="Tanggal Gaji">{ dayjs( dataGaji.tanggalGaji, 'DD/MM/YYYY' ).format( 'dddd, DD MMMM YYYY' ) }</Descriptions.Item>
            </Descriptions>
            <Descriptions title="Gaji Harian">
                <Descriptions.Item>
                    <Table
                        style={ { width: '100%' } }
                        bordered
                        columns={ columns }
                        size='small'
                        dataSource={ dataGajiHarian }
                        rowKey={ record => record._id.$oid }
                        pagination={ false }
                    />
                </Descriptions.Item>
            </Descriptions>

            <Descriptions title="Gaji Lembur">
                <Descriptions.Item>
                    <Table
                        style={ { width: '100%' } }
                        bordered
                        columns={ columns }
                        size='small'
                        dataSource={ dataGajiLembur }
                        rowKey={ record => record._id.$oid }
                        pagination={ false }
                    />
                </Descriptions.Item>
            </Descriptions>
            <Popconfirm
                title="Apakah kamu yakin menghapus data ini ?"
                onConfirm={ handleDelete }
                okText="Yes"
                cancelText="No"
            >
                <Button type='default' danger >
                    Hapus
                </Button>
            </Popconfirm>
        </Spin>
    );
};

export default DataGajiCek;
