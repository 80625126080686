import React, { useEffect, useState } from 'react';
import { Table, Button, DatePicker, Drawer, Select, message, InputNumber } from 'antd';
import { PiCursorClickThin } from 'react-icons/pi';
import dayjs from 'dayjs';
import * as XLSX from 'xlsx';
import 'dayjs/locale/id'; // Import bahasa Indonesia untuk dayjs
import { SlRefresh } from 'react-icons/sl';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import BayarDenda from './bayarDenda';
import EditDenda from './editDenda';
import CreateAxiosInstance from '../../../../Authentication/contexts/axiosConfig';
dayjs.locale( 'id' ); // Set lokal dayjs ke bahasa Indonesia
dayjs.extend( isSameOrBefore );
const FineData = () =>
{
    const axiosConfig = CreateAxiosInstance();
    const [ loading, setLoading ] = useState( false );
    const [ drawerEdit, setDrawerEdit ] = useState( false );
    const [ customerData, setCustomerData ] = useState( [] );
    const [ dendaTidakTerbayar, setDendaTidakTerbayar ] = useState( 0 );
    const [ dendaSudahDibayar, setDendaSudahTerbayar ] = useState( 0 );
    const [ selectedAction, setSelectedAction ] = useState( "" );
    const [ filterLocation, setFilterLocation ] = useState( [] );
    const [ drawerPayment, setDrawerPayment ] = useState( false );
    const [ filteredData, setFilteredData ] = useState( [] );
    const [ selectedMonth, setSelectedMonth ] = useState( dayjs() );
    const fetchData = async () =>
    {
        setLoading( true );
        try
        {
            const response = await axiosConfig.get( `${ process.env.REACT_APP_LOCAL_API }/customer/data` );
            const updateData = response.data.filter( f => f.statusPembelian === 'BELUM LUNAS' && f.dendaCustomer > 0 )
                .filter( ft =>
                {
                    const dendaTrbyr = ft.dataBayarDenda.reduce( ( total, item ) =>
                        total + ( item.jumlahByrDenda || 0 ), 0 );
                    return dendaTrbyr !== ft.dendaCustomer;
                } );
            setCustomerData( updateData );
            setFilterLocation( getUniqueLocation( updateData ) );
        } catch ( error )
        {
            console.error( error );
        } finally
        {
            setLoading( false );
        }
    };
    const handleRefresh = () =>
    {
        fetchData();
    };
    const getUniqueLocation = filteredData =>
    {
        const uniqueTypes = [ ...new Set( filteredData.map( item => item.lokasiKavling ) ) ];
        return uniqueTypes.map( type => ( { text: type, value: type } ) );
    };
    const handleSelectMonth = ( value ) =>
    {
        setSelectedMonth( value );
    }
    useEffect( () =>
    {
        fetchData()
    }, [] )
    useEffect( () =>
    {
        if ( selectedMonth )
        {
            const endOfMonth = dayjs( selectedMonth ).endOf( 'month' );
            const filterData = customerData.map( db =>
            {
                const dataDenda = db.dataBayarDenda.filter( item =>
                    dayjs( item.tanggalByrDenda, 'DD/MM/YYYY' ).isSame( selectedMonth, 'month' )
                );
                const firstAngsuran = db.pembayaranAngsuran[ 0 ]?.tanggalByrAngs;
                const dendaDibayar = dataDenda.reduce( ( total, item ) => total + ( item.jumlahByrDenda || 0 ), 0 );
                return {
                    denda1: firstAngsuran,
                    tglBooking: db.tanggalBooking,
                    nama: db.namaCustomer,
                    blok: ( db.blokKavling === 'TANPABLOK' ? db.noKavling : db.blokKavling + db.noKavling ),
                    lokasi: db.lokasiKavling,
                    jumlahDenda: ( db.dendaCustomer || 0 ),
                    dendaTerbayar: dendaDibayar,
                    sisaDenda: ( db.dendaCustomer || 0 ) - db.dataBayarDenda
                        .filter( item => dayjs( item.tanggalByrDenda, 'DD/MM/YYYY' ).isSameOrBefore( selectedMonth, 'month' ) )
                        .reduce( ( total, item ) => total + ( item.jumlahByrDenda || 0 ), 0 ),
                    jumlahByr: dataDenda.length > 0
                        ? dataDenda.map( dd => convertToIDR( dd.jumlahByrDenda ) ).join( ", " )
                        : convertToIDR( 0 ),
                    tanggalByr: dataDenda.length > 0
                        ? dataDenda.map( item => item.tanggalByrDenda ).join( ", " )
                        : '',
                    metodeByr: dataDenda.length > 0
                        ? dataDenda.map( item => item.metodeByrDenda ).join( ", " )
                        : '',
                };
            } );
            const setData = filterData.filter( item =>
                dayjs( item.denda1, 'DD/MM/YYYY' ).isSameOrBefore( endOfMonth ) );
            const totalByrDenda = setData.reduce( ( total, item ) =>
            {
                const jumlahByrNumbers = item.jumlahByr
                    .split( ', ' )
                    .map( value => parseFloat( value.replace( /[^\d]/g, '' ) ) )
                    .filter( value => !isNaN( value ) );
                return total + jumlahByrNumbers.reduce( ( acc, value ) => acc + value, 0 );
            }, 0 );
            const totalBlmByr = setData.reduce( ( total, item ) =>
            {
                return total + ( item.sisaDenda || 0 );
            }, 0 );
            setFilteredData( setData );
            setDendaTidakTerbayar( totalBlmByr );
            setDendaSudahTerbayar( totalByrDenda );
        } else
        {
            return true;
        }
    }, [ customerData, selectedMonth ] )
    const handleSelectChange = ( value ) =>
    {
        if ( value )
        {
            setSelectedAction( value );
        } else
        {
            setSelectedAction( null );
        }
    }
    const handleSelectAction = () =>
    {
        if ( selectedAction === 'bayarDenda' )
        {
            handlePayment();
        } if ( selectedAction === 'editDenda' )
        {
            handleEdit();
        }
        if ( selectedAction === 'export' )
        {
            // exportToExcel();
            message.info( 'Fitur Belum Tersedia' );
            return;
        }
    };
    const handleEdit = () =>
    {
        setDrawerEdit( true );
    };
    const handleCloseDrawer = () =>
    {
        setDrawerEdit( false );
    };
    const convertToIDR = ( value ) =>
    {
        return new Intl.NumberFormat( 'id-ID', {
            style: 'currency',
            currency: 'IDR',
            maximumFractionDigits: 0,
        } ).format( value );
    };
    const handlePayment = () =>
    {
        setDrawerPayment( true );
    }
    const handleClosePayment = () =>
    {
        setDrawerPayment( false );
    }
    const columns = [
        {
            title: 'Blok Kavling',
            dataIndex: 'blok',
            align: 'center',
            width: '8%',
            sorter: ( a, b ) =>
            {
                const regex = /^(\d+|[a-zA-Z]+)(\d+|[a-zA-Z]*)$/;
                const aMatch = a.blok.match( regex );
                const bMatch = b.blok.match( regex );

                if ( !aMatch || !bMatch ) return 0;

                const aPart1 = aMatch[ 1 ];
                const aPart2 = aMatch[ 2 ] || '';
                const bPart1 = bMatch[ 1 ];
                const bPart2 = bMatch[ 2 ] || '';

                if ( isNaN( aPart1 ) && isNaN( bPart1 ) )
                {
                    // Both parts are strings
                    if ( aPart1 !== bPart1 )
                    {
                        return aPart1.localeCompare( bPart1 );
                    }
                    return aPart2.localeCompare( bPart2 );
                }

                if ( !isNaN( aPart1 ) && !isNaN( bPart1 ) )
                {
                    // Both parts are numbers
                    const numCompare = parseInt( aPart1 ) - parseInt( bPart1 );
                    if ( numCompare !== 0 )
                    {
                        return numCompare;
                    }
                    return aPart2.localeCompare( bPart2 );
                }
                return isNaN( aPart1 ) ? 1 : -1;
            },
            sortDirections: [ 'descend', 'ascend' ],
            defaultSortOrder: 'ascend',
        },
        {
            title: <p style={ { textAlign: 'center' } }>Nama Pelanggan</p>,
            dataIndex: 'nama',
            align: 'left',
            width: 'auto',
            sorter: ( a, b ) => a.nama.localeCompare( b.nama ),
        },
        {
            title: 'Lokasi',
            dataIndex: 'lokasi',
            align: 'center',
            width: '10%',
            filters: filterLocation,
            onFilter: ( value, record ) => record.lokasi.includes( value ),
        },
        {
            title: 'Denda',
            align: 'center',
            width: 'auto',
            children: [
                {
                    title: 'Terbayar',
                    dataIndex: 'dendaTerbayar',
                    align: 'center',
                    width: '15%',
                    render: ( value ) => convertToIDR( value ),
                },
                {
                    title: 'Tertagih',
                    dataIndex: 'sisaDenda',
                    align: 'center',
                    width: '15%',
                    render: ( value ) => convertToIDR( value ),

                },
            ],
        },
        {
            title: 'Pembayaran',
            align: 'center',
            children: [
                {
                    title: 'Jumlah Bayar',
                    dataIndex: 'jumlahByr',
                    align: 'center',
                    width: '10%',
                },
                {
                    title: 'Tanggal Bayar',
                    dataIndex: 'tanggalByr',
                    align: 'center',
                    width: '10%',
                    sorter: ( a, b ) => dayjs( a.tanggalByr, 'DD/MM/YYYY' ) - dayjs( b.tanggalByr, 'DD/MM/YYYY' ),
                },
                {
                    title: 'Metode',
                    dataIndex: 'metodeByr',
                    align: 'center',
                    width: '7%',
                },
            ],
        },
    ];
    // const exportToExcel = () =>
    // {
    //     if ( !filteredData || filteredData.length === 0 )
    //     {
    //         message.error( 'Tidak ada data untuk diekspor' );
    //         return;
    //     }

    //     const ws = XLSX.utils.json_to_sheet( filteredData.map( item => ( {
    //         'Blok': item.blok,
    //         'Nama Pelanggan': item.nama,
    //         'Angsuran': item.angsuran,
    //         'Tenor': item.tenor,
    //         'Sisa': item.sisa,
    //         'Ke': item.angs,
    //         'Jumlah Bayar': parseFloat( item.jumlahByr.replace( /[^\d]/g, '' ) ) === 0 ? undefined
    //             : parseFloat( item.jumlahByr.replace( /[^\d]/g, '' ) ),
    //         'Tanggal Bayar': item.tanggalByr,
    //         'Metode Bayar': item.metodeByr,
    //     } ) ), { header: [ "Blok", "Nama Pelanggan", "Angsuran", "Tenor", "Sisa", "Ke", "Jumlah Bayar", "Tanggal Bayar", "Metode Bayar" ] } );

    //     const wb = XLSX.utils.book_new();
    //     XLSX.utils.book_append_sheet( wb, ws, 'Data Customers' );
    //     XLSX.writeFile( wb, `Data Angsuran ${ selectedMonth.format( 'MMMM YYYY' ) }.xlsx` );
    //     };

    return (
        <div style={ { padding: '0 5px' } }>
            <div style={ { display: 'flex', gap: 5, padding: '10px 0' } }>
                <Button
                    style={ { padding: 5 } }
                    onClick={ handleRefresh }
                    icon={ <SlRefresh /> } />
                <Button
                    style={ { padding: 5 } }
                    type='primary'
                    onClick={ handleSelectAction }
                    icon={ <PiCursorClickThin /> } />
                <Select
                    style={ { width: '50%' } }
                    placeholder='Pilih Aksi'
                    onChange={ handleSelectChange }
                    allowClear
                    options={ [
                        { value: 'bayarDenda', label: 'Bayar Denda' },
                        { value: 'editDenda', label: 'Edit Denda' },
                        { value: 'export', label: 'Ekspor Ke Excel' },
                    ] } />
                <InputNumber
                    style={ { width: '100%' } }
                    value={ dendaSudahDibayar >= 1 && convertToIDR( dendaSudahDibayar ) }
                    placeholder={ dendaSudahDibayar < 1 && 'Tidak Ada Angsuran Terbayar' }
                    addonBefore={ dendaSudahDibayar >= 1 && 'Terbayar' }
                    readOnly />
                <InputNumber
                    style={ { width: '100%' } }
                    value={ dendaTidakTerbayar >= 1 && convertToIDR( dendaTidakTerbayar ) }
                    placeholder={ dendaTidakTerbayar < 1 && 'Tidak Ada Angsuran Tertagih' }
                    addonBefore={ dendaTidakTerbayar >= 1 && 'Tertagih' }
                    readOnly />
                <DatePicker.MonthPicker
                    style={ { width: '50%' } }
                    onChange={ handleSelectMonth }
                    allowClear={ false }
                    value={ selectedMonth }
                    picker="month"
                    format={ ( value ) => dayjs( value ).format( 'MMMM YYYY' ) }
                    placeholder='Pilih Bulan'
                />
            </div>
            <Table
                size="small"
                pagination={ { pageSize: 20 } }
                scroll={ { y: 340 } }
                dataSource={ filteredData }
                columns={ columns }
                rowKey="_id"
                loading={ loading }
                showSorterTooltip={ false }
            />
            <Drawer
                title="Edit Denda"
                placement="right"
                closable={ true }
                onClose={ handleCloseDrawer }
                visible={ drawerEdit }
                width='40%'
            >
                <EditDenda
                    onClose={ handleCloseDrawer }
                    fetchData={ fetchData }
                />
            </Drawer>
            <Drawer
                title="Bayar Denda"
                placement="right"
                closable={ true }
                onClose={ handleClosePayment }
                visible={ drawerPayment }
                width='40%'
            >
                <BayarDenda
                    onClose={ handleClosePayment }
                    fetchData={ fetchData }
                />
            </Drawer>
        </div>
    );
};

export default FineData;
