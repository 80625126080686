import { useState } from 'react';
import { useAuth } from '../contexts/authContext';
import { message } from 'antd';
import axios from 'axios';

const useLogin = () =>
{
    const apiUrl = process.env.REACT_APP_LOCAL_API;
    const { login } = useAuth();
    const [ error, setError ] = useState( null );
    const [ loading, setLoading ] = useState( false );

    const loginUser = async ( values ) =>
    {
        try
        {
            setError( null );
            setLoading( true );
            const res = await axios.post( `${ apiUrl }/api/auth/login`, values );
            if ( res.status === 200 )
            {
                const data = res.data;
                if ( data.token && data.user )
                {
                    message.success( 'Berhasil Login' );
                    login( data.token, data.user );
                } else
                {
                    throw new Error( "Data pengguna tidak lengkap dalam respons." );
                }
            }
        } catch ( error )
        {
            if ( error.status === 400 )
            {
                message.error( error.response.data.error )
            } else
            {
                const errorMessage = error.response?.data?.message || error.message;
                setError( errorMessage );
                message.error( errorMessage );
            }
        } finally
        {
            setLoading( false );
        }
    };

    return { loading, error, loginUser };
};

export default useLogin;
