import { Card, Collapse, Progress, Spin, Statistic, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import './statisticFinance.css'
import CreateAxiosInstance from '../../../Authentication/contexts/axiosConfig';
const { Panel } = Collapse;
const FinanceStatistic = () =>
{
    const axiosConfig = CreateAxiosInstance();
    const [ dataLahan, setDataLahan ] = useState( [] );
    const [ pengeluaranMaterial, setPengeluaranMaterial ] = useState( [] );
    const [ totalKasihMedia, setTotalKasihMedia ] = useState( 0 );
    const [ totalKomisiTerbayar, setTotalKomisiTerbayar ] = useState( 0 );
    const [ komisiTotal, setKomisiTotal ] = useState( 0 );
    const [ totalOtherPayment, setTotalOtherPayment ] = useState( 0 );
    const [ totalGajiTukang, setTotalGajiTukang ] = useState( 0 );
    const [ totalPTT, setTotalPTT ] = useState( 0 );
    const [ totalSalaryEmployee, setTotalSalaryEmployee ] = useState( 0 );
    const [ loading, setLoading ] = useState( false );
    const [ totaPinjamanDibayar, setTotalPinjamanDibayar ] = useState( 0 );
    const [ totaPinjamanBlmDibayar, setTotalPinjamanBlmDibayar ] = useState( 0 );
    const [ totalDipinjam, setTotalDipinjam ] = useState( 0 );
    // const [ dataAngsCust, setDataAngsCust ] = useState( 0 );
    // const [ dataDpCust, setDataDpCust ] = useState( 0 );
    useEffect( () =>
    {
        fetchData();
    }, [] );
    const fetchData = async () =>
    {
        setLoading( true );
        try
        {
            const response1 = await axiosConfig.get( `${ process.env.REACT_APP_LOCAL_API }/lahan/list/data` );
            const response2 = await axiosConfig.get( `${ process.env.REACT_APP_LOCAL_API }/materials/list` );
            const response3 = await axiosConfig.get( `${ process.env.REACT_APP_LOCAL_API }/sales/data` );
            const response4 = await axiosConfig.get( `${ process.env.REACT_APP_LOCAL_API }/media/payment` );
            const response5 = await axiosConfig.get( `${ process.env.REACT_APP_LOCAL_API }/salaryMan/data` );
            const response6 = await axiosConfig.get( `${ process.env.REACT_APP_LOCAL_API }/employee/list` );
            const response7 = await axiosConfig.get( `${ process.env.REACT_APP_LOCAL_API }/additionalExpenses/list` );
            const response8 = await axiosConfig.get( `${ process.env.REACT_APP_LOCAL_API }/loan/list` );
            // const response9 = await axiosConfig.get( '${ process.env.REACT_APP_LOCAL_API }/customer/data' );

            const data1 = response1.data;

            const data2 = response2.data
                .reduce( ( total, item ) => total + item.hargaMaterial, 0 );

            const data3 = response3.data;
            const setData3 = data3.map( db => ( {
                komisiTerbayar: db.dataKomisi.reduce( ( total, item ) => total + ( item.jumlahByrKms || 0 ), 0 ),
            } ) );
            const komisiTerbayar = setData3.reduce( ( total, item ) => total + item.komisiTerbayar, 0 );
            const totalSemuaKomisi = data3.reduce( ( total, item ) => total + item.totalKomisi, 0 )

            const data4 = response4.data;
            const totalBayarMedia = data4.filter( ( jenis ) => jenis.jenis === 'MEDIA' )
                .reduce( ( total, item ) => total + item.jumlahKasih, 0 );
            const otherPayment = data4.filter( ( jenis ) => jenis.jenis !== 'MEDIA' )
                .reduce( ( total, item ) => total + item.jumlahKasih, 0 );

            const data5 = response5.data;
            const totalGaji = data5.reduce( ( total, item ) => total + item.totalPembayaranGaji, 0 );

            const data6 = response6.data;
            const salaryData = data6.flatMap( fm => fm.dataSalary.map( mp => mp.salary ) );
            const additionalSalaryData = data6.flatMap( fm => fm.dataSalary.map( mp => mp.additionalSalary ) );
            const totalSalary = salaryData.reduce( ( total, value ) => total + value, 0 )
            const totalAdditionalSalary = additionalSalaryData.reduce( ( total, value ) => total + value, 0 )
            const totalEmployeeSalary = ( totalSalary + totalAdditionalSalary );

            const data7 = response7.data;
            const totalPengeluaranLain = data7.reduce( ( total, item ) => total + item.hargaPTT, 0 );

            const data8 = response8.data;
            const allLoanPayment = data8.flatMap( data => data.riwayatPembayaran.map( item => item.paymentValue ) );
            const totalLoanPay = allLoanPayment.reduce( ( total, value ) => total + value, 0 );
            const allLoanTotal = data8.reduce( ( total, item ) => total + ( item.jumlahPinjaman || 0 ), 0 )
            const totalLoan = allLoanTotal - totalLoanPay;

            // const data9 = response9.data;
            // const dataAngsuran = data9.filter( item => item.BelumDibayar > 0 && item.TotalDibayar > item.uangMukaKavling )
            // const dataDownPayment = data9.filter( item => item.BelumDibayar > 0 && item.TotalDibayar <= item.uangMukaKavling )
            // let totalJumlahByrAngs = 0;
            // dataAngsuran.forEach( customer =>
            // {
            //     const pembayaranAngsuran = customer.pembayaranAngsuran;
            //     const totalByrCustomer = pembayaranAngsuran.reduce( ( total, angsuran ) =>
            //     {
            //         return total + angsuran.jumlahByrAngs;
            //     }, 0 );
            //     totalJumlahByrAngs += totalByrCustomer;
            // } );

            // let totalJumlahByrDp = 0;
            // dataDownPayment.forEach( customer =>
            // {
            //     const pembayaranDP = customer.pembayaranDP || [];
            //     const totalByrDpCustomer = pembayaranDP.reduce( ( total, dp ) =>
            //     {
            //         return total + dp.jumlahByrDp;
            //     }, 0 );
            //     totalJumlahByrDp += totalByrDpCustomer;
            // } );
            // setDataAngsCust( totalJumlahByrAngs )
            // setDataDpCust( totalJumlahByrDp )
            setDataLahan( data1 );
            setPengeluaranMaterial( data2 );
            setTotalKomisiTerbayar( komisiTerbayar );
            setKomisiTotal( totalSemuaKomisi );
            setTotalKasihMedia( totalBayarMedia );
            setTotalOtherPayment( otherPayment );
            setTotalGajiTukang( totalGaji );
            setTotalPTT( totalPengeluaranLain );
            setTotalSalaryEmployee( totalEmployeeSalary );
            setTotalDipinjam( allLoanTotal );
            setTotalPinjamanBlmDibayar( totalLoan );
            setTotalPinjamanDibayar( totalLoanPay );
        } catch ( error )
        {
            console.error( 'Error fetching data:', error );
        } finally
        {
            setLoading( false );
        }
    };
    const convertToIDR = ( value ) =>
    {
        return new Intl.NumberFormat( 'id-ID', {
            style: 'currency',
            currency: 'IDR',
            maximumFractionDigits: 0
        } ).format( value );
    };
    return (
        <Spin spinning={ loading }>
            <Typography.Title style={ { textAlign: 'center' } }>Statistik Keuangan</Typography.Title>
            <div className='layout-dashboardFinance'>
                <Collapse accordion defaultActiveKey='1' className='collapse'>
                    {/* <Panel header='Statistik Pembayaran Customer' key='0'>
                        <Card bordered>
                            <Statistic
                                title='Jumlah Piutang'
                                value={ `${ convertToIDR ( totaPinjamanDibayar ) }/${ convertToIDR ( totalDipinjam ) }` }
                            />
                        </Card>
                        <Progress
                            format={ ( percent ) => `${ percent.toFixed( 2 ) } % ` }
                            percent={ ( totaPinjamanDibayar / totalDipinjam ) * 100 } />
                    </Panel> */}
                    <Panel header='Data Pembayaran Lahan' key='1'>
                        { dataLahan.map( ( lahan ) => (
                            <Card bordered className='statistic'>
                                <Statistic
                                    title={ `Pembayaran Lahan ${ lahan.landCode } - ${ lahan.farmerName }` }
                                    value={ ` Terbayar ${ convertToIDR( lahan.landPayment
                                        .reduce( ( total, item ) => total + ( item.jumlahByrLhn || 0 ), 0 ) )
                                        } dari 
                                ${ convertToIDR( lahan.landPrice ) } ` }
                                />
                                <Progress
                                    format={ ( percent ) => `${ percent.toFixed( 2 ) }% ` }
                                    percent={ ( lahan.landPayment
                                        .reduce( ( total, item ) => total + ( item.jumlahByrLhn || 0 ), 0 ) /
                                        lahan.landPrice ) * 100 } />
                            </Card>
                        ) ) }
                    </Panel>
                    <Panel header='Total Pembiayaan Material' key='2'>
                        <Card bordered>
                            <Statistic
                                title='Jumlah Total Pembiayaan Material'
                                value={ convertToIDR( pengeluaranMaterial ) }
                            />
                        </Card>
                    </Panel>
                    <Panel header='Total Pembiayaan Gaji Karyawan' key='4'>
                        <Card bordered>
                            <Statistic
                                title='Jumlah Total Pembiayaan Gaji Karyawan'
                                value={ convertToIDR( totalSalaryEmployee ) }
                            />
                        </Card>
                    </Panel>
                    <Panel header='Total Pembiayaan Tukang' key='5'>
                        <Card bordered>
                            <Statistic
                                title='Jumlah Total Pembiayaan Gaji Tukang'
                                value={ convertToIDR( totalGajiTukang ) }
                            />
                        </Card>
                    </Panel>
                    <Panel header='Total Pembiayaan Komisi' key='6'>
                        <Card bordered>
                            <Statistic
                                title='Jumlah Pembiayaan Komisi'
                                value={ convertToIDR( komisiTotal ) }
                            />
                        </Card>
                        <Progress
                            format={ ( percent ) => `${ percent.toFixed( 2 ) }% ` }
                            percent={ ( totalKomisiTerbayar / komisiTotal ) * 100 } />
                        <div style={ { display: 'flex', gap: 10 } }>
                            <Card bordered style={ { width: '100%' } }>
                                <Statistic
                                    title='Komisi Telah Di Ambil'
                                    value={ convertToIDR( totalKomisiTerbayar ) } />
                            </Card>
                            <Card bordered style={ { width: '100%' } }>
                                <Statistic
                                    title='Komisi Belum Di Ambil'
                                    value={ convertToIDR( komisiTotal - totalKomisiTerbayar ) } />
                            </Card>
                        </div>
                    </Panel>
                    <Panel header='Total Pembiayaan Pinjaman' key='7'>
                        <Card bordered>
                            <Statistic
                                title='Jumlah Pembiayaan Pinjaman'
                                value={ convertToIDR( totalDipinjam ) }
                            />
                        </Card>
                        <Progress
                            format={ ( percent ) => `${ percent.toFixed( 2 ) }% ` }
                            percent={ ( totaPinjamanDibayar / totalDipinjam ) * 100 } />
                        <div style={ { display: 'flex', gap: 10 } }>
                            <Card bordered style={ { width: '100%' } }>
                                <Statistic
                                    title='Telah Dibayar'
                                    value={ convertToIDR( totaPinjamanDibayar ) }
                                />
                            </Card>
                            <Card bordered style={ { width: '100%' } }>
                                <Statistic
                                    title='Belum Dibayar'
                                    value={ convertToIDR( totaPinjamanBlmDibayar ) }
                                />
                            </Card>
                        </div>
                    </Panel>
                    <Panel header='Total Pembayaran Lain' key='8'>
                        <div style={ { display: 'flex', gap: 10 } }>
                            <Card bordered style={ { width: '100%' } }>
                                <Statistic
                                    title='Jumlah Pembiayaan Media'
                                    value={ convertToIDR( totalKasihMedia ) } />
                            </Card>
                            <Card bordered style={ { width: '100%' } }>
                                <Statistic
                                    title='Jumlah Pembiayaan Lain'
                                    value={ convertToIDR( totalOtherPayment ) } />
                            </Card>
                        </div>
                    </Panel>
                    <Panel header='Total Pembiayaan Operasional Dan Lain-Lain' key='9'>
                        <Card bordered>
                            <Statistic
                                title='Jumlah Total Pembiayaan'
                                value={ convertToIDR( totalPTT ) }
                            />
                        </Card>
                    </Panel>
                </Collapse>
            </div>
        </Spin>
    );
};

export default FinanceStatistic;
