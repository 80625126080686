import React from 'react';
import { Result, Button } from 'antd';
import { SmileOutlined } from '@ant-design/icons';

const UnderDevelopment = () =>
{
    return (
        <div style={ { display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' } }>
            <Result
                icon={ <SmileOutlined /> }
                title="Halaman Ini Masih Dalam Tahap Pengembangan"
                subTitle="Kami sedang bekerja untuk menyelesaikan fitur ini. Terima kasih atas kesabaran Anda!"
            />
        </div>
    );
};

export default UnderDevelopment;
