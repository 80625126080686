import { Button, Form, InputNumber, message, Popconfirm, Select, Spin, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import CreateAxiosInstance from '../../../Authentication/contexts/axiosConfig';

const { Option } = Select;

const DrawerEditGajiHarian = ( { fetchData, onClose, recordId } ) =>
{
    const axiosConfig = CreateAxiosInstance();
    const [ form ] = Form.useForm();
    const [ employeeData, setEmployeeData ] = useState( [] );
    const [ dailyWages, setDailyWages ] = useState( {} );
    const days = [ 'senin', 'selasa', 'rabu', 'kamis', 'jumat', 'sabtu', 'minggu' ];
    const [ gajiData, setGajiData ] = useState();
    const [ loading, setLoading ] = useState( false );

    useEffect( () =>
    {
        const fetchEmployeById = async () =>
        {
            setLoading( true );
            try
            {
                const response = await axiosConfig.get( `${ process.env.REACT_APP_LOCAL_API }/salaryMan/data/${ recordId }` );
                const response1 = await axiosConfig.get( `${ process.env.REACT_APP_LOCAL_API }/pekerja` );
                setEmployeeData( response1.data );
                form.setFieldsValue( response.data );
                setGajiData( response.data );
            } catch ( error )
            {
                onClose();
                message.error( 'Gagal mengambil data dari server' );
            } finally
            {
                setLoading( false );
            }
        };

        if ( recordId )
        {
            fetchEmployeById();
        }
    }, [ recordId, form, onClose ] );

    const onPosisiChange = ( value, index ) =>
    {
        const wageOptions = {
            baru: 115000,
            kuli: 125000,
            tukang: 140000,
        };
        const wage = wageOptions[ value ];
        const updatedWages = days.reduce( ( acc, day ) =>
        {
            acc[ day ] = day === 'jumat' ? 0 : wage; // Nilai khusus untuk hari Jumat
            return acc;
        }, {} );
        setDailyWages( ( prevWages ) => ( {
            ...prevWages,
            [ index ]: updatedWages,
        } ) );

        const currentFieldsValue = form.getFieldsValue();
        const updatedFields = currentFieldsValue.gajiHarian.map( ( item, idx ) =>
        {
            if ( idx === index )
            {
                return { ...item, ...updatedWages, posisiPekerja: value };
            }
            return item;
        } );

        form.setFieldsValue( {
            gajiHarian: updatedFields,
        } );
    };

    const handleWageChange = ( index, day, value ) =>
    {
        setDailyWages( ( prevWages ) => ( {
            ...prevWages,
            [ index ]: {
                ...prevWages[ index ],
                [ day ]: value,
            },
        } ) );

        const currentFieldsValue = form.getFieldsValue();
        const updatedFields = currentFieldsValue.gajiHarian.map( ( item, idx ) =>
        {
            if ( idx === index )
            {
                return { ...item, [ day ]: value };
            }
            return item;
        } );

        form.setFieldsValue( {
            gajiHarian: updatedFields,
        } );
    };

    const convertToIDR = ( value ) =>
    {
        return new Intl.NumberFormat( 'id-ID', {
            style: 'currency',
            currency: 'IDR',
        } ).format( value );
    };

    const optionsWithTooltip = [
        { value: 'baru', label: 'BARU', tooltip: convertToIDR( 115000 ) },
        { value: 'kuli', label: 'KULI', tooltip: convertToIDR( 125000 ) },
        { value: 'tukang', label: 'TUKANG', tooltip: convertToIDR( 140000 ) },
    ];

    const onFinish = async ( values ) =>
    {
        let totalGajiMingguan = 0;
        values.gajiHarian.forEach( ( item ) =>
        {
            days.forEach( ( day ) =>
            {
                totalGajiMingguan += item[ day ] || 0;
            } );
        } );

        const totalPembayaranGaji = totalGajiMingguan + ( gajiData?.totalGajiLembur || 0 ) + ( gajiData?.uangMakanTukang || 0 );

        const paymentData = {
            ...values,
            totalGajiMingguan,
            totalPembayaranGaji,
        };

        try
        {
            setLoading( true );
            await axiosConfig.put( `${ process.env.REACT_APP_LOCAL_API }/salaryMan / data / ${ recordId }`, paymentData );
            form.resetFields();
            onClose();
            fetchData();
            message.success( 'Data gaji mingguan berhasil diperbarui' );
        } catch ( error )
        {
            message.error( 'Data gagal diperbarui' );
        } finally
        {
            setLoading( false );
        }
    };

    return (
        <Spin spinning={ loading }>
            <Form form={ form } name='gaji_form' onFinish={ onFinish } layout='vertical'>
                <Form.List name='gajiHarian'>
                    { ( fields, { add, remove } ) => (
                        <>
                            { fields.map( ( { key, name, fieldKey, ...restField }, index ) => (
                                <div key={ key }>
                                    <div style={ { display: 'flex', gap: 5 } }>
                                        <Form.Item
                                            { ...restField }
                                            name={ [ name, 'nama' ] }
                                            style={ { width: '100%' } }
                                            fieldKey={ [ fieldKey, 'nama' ] }
                                            label='Nama Pekerja'
                                            rules={ [ { required: true, message: 'Nama pekerja diperlukan!' } ] }
                                        >
                                            <Select
                                                placeholder='Pilih Pekerja'
                                                allowClear
                                                showSearch
                                                style={ { width: '100%' } }
                                                options={ employeeData.map( ( emp ) => ( {
                                                    value: emp.nama,
                                                    label: emp.nama,
                                                } ) ) }
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            { ...restField }
                                            name={ [ name, 'posisiPekerja' ] }
                                            fieldKey={ [ fieldKey, 'posisiPekerja' ] }
                                            label='Posisi Pekerja'
                                            style={ { width: '100%' } }
                                        >
                                            <Select
                                                placeholder='Pilih Opsi Gaji'
                                                style={ { width: '100%' } }
                                                onChange={ ( value ) => onPosisiChange( value, index ) }
                                            >
                                                { optionsWithTooltip.map( ( option ) => (
                                                    <Option key={ option.value } value={ option.value }>
                                                        <Tooltip title={ option.tooltip }>{ option.label }</Tooltip>
                                                    </Option>
                                                ) ) }
                                            </Select>
                                        </Form.Item>
                                    </div>
                                    <div
                                        style={ {
                                            display: 'grid',
                                            gridTemplateColumns: 'repeat(7, 1fr)',
                                            gap: '5px',
                                        } }
                                    >
                                        { days.map( ( day ) => (
                                            <Form.Item
                                                key={ day }
                                                label={ `Gaji ${ day.charAt( 0 ).toUpperCase() + day.slice( 1 ) }` }
                                                name={ [ name, day ] }
                                                fieldKey={ [ fieldKey, day ] }
                                            >
                                                <InputNumber
                                                    value={ dailyWages[ index ]?.[ day ] ?? 0 }
                                                    onChange={ ( value ) => handleWageChange( index, day, value ) }
                                                    style={ { width: '100%' } }
                                                    min={ 0 }
                                                    addonBefore='Rp'
                                                    placeholder='Masukkan gaji'
                                                />
                                            </Form.Item>
                                        ) ) }
                                    </div>
                                    <Button
                                        style={ { marginBottom: '10px', width: '200px' } }
                                        danger
                                        onClick={ () => remove( name ) }>
                                        Hapus Gaji Pekerja
                                    </Button>
                                </div>
                            ) ) }
                            <Button
                                onClick={ () => add() }
                                style={ { width: '200px' } }>
                                Tambah Gaji Pekerja
                            </Button>
                        </>
                    ) }
                </Form.List>
                <Form.Item>
                    <Popconfirm
                        placement='right'
                        title='Apakah kamu yakin melakukan update data ini?'
                        onConfirm={ () => form.submit() }
                        okText='Yes'
                        cancelText='No'
                    >
                        <Button style={ { marginTop: 10, width: '200px' } } type='primary' >
                            Update Gaji Harian
                        </Button>
                    </Popconfirm>
                </Form.Item>
            </Form>
        </Spin>
    );
};

export default DrawerEditGajiHarian;
