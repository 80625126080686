import { Button, DatePicker, Form, Input, InputNumber, message, Select, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import CreateAxiosInstance from '../../../Authentication/contexts/axiosConfig';
const { Option } = Select;
const EditPaymentWaterAndLamp = ( { fetchData, onClose } ) =>
{
    const axiosConfig = CreateAxiosInstance();
    const [ form ] = Form.useForm();
    const [ loading, setLoading ] = useState( false );
    const [ clientData, setClientData ] = useState( [] );
    const [ locationData, setLocationData ] = useState( [] );
    const [ blockData, setBlockData ] = useState( [] );
    const [ paymentId, setPaymentId ] = useState( null );
    const [ clientId, setClientId ] = useState( null );
    const [ dataKavling, setDataKavling ] = useState( [] );
    const [ yearData, setYearData ] = useState( [] );
    const fetchClient = async () =>
    {
        try
        {
            setLoading( true );
            const response = await axiosConfig.get( `${ process.env.REACT_APP_LOCAL_API }/customer/data` );
            setClientData( response.data.filter( f => f.air === 'ya' ) );
        } catch ( error )
        {
            onClose();
            message.error( 'Gagal mengambil data customer' );
        } finally
        {
            setLoading( false );
        }
    };

    useEffect( () =>
    {
        fetchClient();
    }, [] );

    useEffect( () =>
    {
        setBlockData( [] );
    }, [ locationData ] );
    useEffect( () =>
    {
        setDataKavling( [] );
    }, [ blockData ] );
    useEffect( () =>
    {
        setYearData( [] );
    }, [ dataKavling ] );
    const handleDelete = async () =>
    {
        try
        {
            await axiosConfig.delete( `${ process.env.REACT_APP_LOCAL_API }/customer/${ clientId }/airLampu/${ paymentId }` );
            message.success( 'Berhasil menghapus data pembayaran' );
            await fetchClient();
            form.resetFields();
            onClose();
            await fetchData();
        } catch ( error )
        {
            message.error( 'Gagal menghapus data pembayaran' );
            console.error( 'Error:', error );
        }
    };
    const handleLocationSelect = ( value ) =>
    {
        const filterLocation = clientData.filter( item => item.lokasiKavling === value );
        const sortLocation = filterLocation.sort( ( a, b ) => a.blokKavling - b.blokKavling );
        if ( filterLocation.length > 0 )
        {
            setLocationData( sortLocation );
            message.success( `${ filterLocation.length } blok ditemukan` );
        } else
        {
            setLocationData( [] );
            message.error( 'Tidak ada blok ditemukan' );
        };
        form.resetFields( [
            'blokKavling',
            'noKavling',
            'tahunPembayaran',
            'listPembayaran',
            'jumlahByrAir',
            'jumlahKubik',
            'tanggalByrAir',
            'metodeByrAir',
            'namaCustomer',
            'jumlahByrLm',
            'tanggalByrLm',
            'metodeByrLm',
        ] );
    };
    const handleBlockSelect = ( value ) =>
    {
        const filterBlock = locationData.filter( item => item.blokKavling === value );
        const sortBlock = filterBlock.sort( ( a, b ) => a.noKavling - b.noKavling );
        if ( filterBlock.length > 0 )
        {
            setBlockData( sortBlock );
            message.success( `${ filterBlock.length } kavling ditemukan` );
        } else
        {
            message.error( 'Tidak ada kavling ditemukan' );
        }
        form.resetFields( [
            'noKavling',
            'namaCustomer',
            'jumlahByrAir',
            'jumlahKubik',
            'tahunPembayaran',
            'listPembayaran',
            'tanggalByrAir',
            'metodeByrAir',
            'jumlahByrLm',
            'tanggalByrLm',
            'metodeByrLm',
        ] );
    };
    const handleKavlingSelect = ( value ) =>
    {
        const dataPel = blockData.find( item => item._id === value );
        form.setFieldsValue( {
            namaCustomer: dataPel.namaCustomer,
        } );
        if ( dataPel.pembayaranAirLampu.length > 0 )
        {
            message.success( `${ dataPel.pembayaranAirLampu.length } pembayaran ditemukan` );
        } else
        {
            message.error( 'Tidak ada pembayaran ditemukan' );
        };
        setClientId( value );
        setDataKavling( dataPel );
        form.resetFields( [
            'jumlahByrAir',
            'jumlahKubik',
            'tahunPembayaran',
            'listPembayaran',
            'tanggalByrAir',
            'metodeByrAir',
            'jumlahByrLm',
            'tanggalByrLm',
            'metodeByrLm',
        ] );
    };
    const handleYearSelect = ( date ) =>
    {
        const paymentData = dataKavling.pembayaranAirLampu;
        if ( paymentData )
        {
            const yearFilter = paymentData.filter( item => dayjs( item.tanggalByrLm || item.tanggalByrAir, 'DD/MM/YYYY' ).isSame( date, 'year' ) );
            setYearData( yearFilter );
            if ( yearFilter.length > 0 )
            {
                message.success( `${ yearFilter.length } pembayaran ditemukan` );
            } else
            {
                message.error( 'Tidak ada pembayaran ditemukan' );
            };
        } else
        {
            message.info( 'Lengkapi data sebelumnya' )
            setYearData( [] )
        }
        form.resetFields( [
            'jumlahByrAir',
            'jumlahKubik',
            'tanggalByrAir',
            'metodeByrAir',
            'jumlahByrLm',
            'tanggalByrLm',
            'metodeByrLm',
        ] );
    }
    const handleSelectList = ( value ) =>
    {
        const detailPayment = yearData.find( item => item._id === value );
        form.setFieldsValue( {
            jumlahByrLm: detailPayment.jumlahByrLm,
            tanggalByrLm: dayjs( detailPayment.tanggalByrLm, 'DD/MM/YYYY' ),
            metodeByrLm: detailPayment.metodeByrLm,
            jumlahByrAir: detailPayment.jumlahByrAir,
            jumlahKubik: detailPayment.jumlahKubik,
            tanggalByrAir: dayjs( detailPayment.tanggalByrAir, 'DD/MM/YYYY' ),
            metodeByrAir: detailPayment.metodeByrAir,
        } );
        setPaymentId( value );
    };
    const onFinish = async ( values ) =>
    {
        if ( !values.jumlahByrAir && !values.jumlahByrLm )
        {
            message.info( 'Tidak ada pembayaran untuk dibayar' );
            return;
        }
        if ( values.jumlahByrAir && !values.metodeByrAir )
        {
            message.error( 'Tentukan metode pembayaran!' )
            return;
        }
        if ( values.metodeByrAir && !values.tanggalByrAir )
        {
            message.error( 'Tentukan tanggal pembayaran!' )
            return;
        }
        if ( values.jumlahByrLm && !values.metodeByrLm )
        {
            message.error( 'Tentukan metode pembayaran!' )
            return;
        }
        if ( values.metodeByrLm && !values.tanggalByrLm )
        {
            message.error( 'Tentukan tanggal pembayaran!' )
            return;
        }
        const payment = {
            ...values,
            tanggalByrAir: dayjs( values.tanggalByrAir ).format( 'DD/MM/YYYY' ),
            tanggalByrLm: dayjs( values.tanggalByrLm ).format( 'DD/MM/YYYY' ),
        };
        try
        {
            setLoading( true );
            await axiosConfig.put( `${ process.env.REACT_APP_LOCAL_API }/customer/${ clientId }/airLampu/${ paymentId }`, payment );
            form.resetFields();
            fetchClient();
            message.success( 'Berhasil menambahkan pembayaran' );
            fetchData();
            onClose();
        } catch ( error )
        {
            message.error( 'Gagal menambahkan pembayaran' );
        } finally
        {
            setLoading( false );
        };
    };

    return (
        <Spin spinning={ loading }>
            <Form form={ form } layout="vertical" onFinish={ onFinish }>
                <div className="payments-flex">
                    <Form.Item
                        label="Pilih Lokasi"
                        name="lokasiKavling"
                        style={ { width: '100%' } }
                    >
                        <Select
                            style={ { width: '100%' } }
                            placeholder="Pilih Lokasi"
                            onChange={ handleLocationSelect }
                            options={ [
                                { value: 'PETAL', label: 'PETAL' },
                                { value: 'DOMAS', label: 'DOMAS' },
                                { value: 'TANJUNG', label: 'TANJUNG' },
                            ] }
                        />
                    </Form.Item>
                    <Form.Item
                        label="Pilih Blok"
                        name="blokKavling"
                        style={ { width: '100%' } }
                    >
                        <Select
                            style={ { width: '100%' } }
                            placeholder="Pilih Blok"
                            onChange={ handleBlockSelect }
                        >
                            {
                                [ ...new Set( locationData.map( blok => blok.blokKavling ) ) ]
                                    .map( ( blokKavling, index ) => (
                                        <Option key={ index } value={ blokKavling }>
                                            { blokKavling }
                                        </Option>
                                    ) )
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="No Kavling"
                        style={ { width: '100%' } }
                        name="noKavling"
                        rules={ [ { required: true, message: 'Pilih kavling!' } ] }
                    >
                        <Select
                            placeholder="Pilih Kavling"
                            onChange={ handleKavlingSelect }
                            style={ { width: '100%' } }
                            options={ blockData.map( c => (
                                { value: c._id, label: c.noKavling }
                            ) ) }
                        />
                    </Form.Item>
                </div>
                <div className="payments-flex">
                    <Form.Item
                        label="Nama Pelanggan"
                        name="namaCustomer"
                        style={ { width: '100%' } }
                    >
                        <Input style={ { width: '100%' } } placeholder="Nama Pelanggan" readOnly />
                    </Form.Item>
                    <Form.Item
                        label="Tahun Pembayaran"
                        name="tahunPembayaran"
                        style={ { width: '100%' } }
                    >
                        <DatePicker.YearPicker
                            onChange={ handleYearSelect }
                            format='YYYY'
                            picker='year'
                            style={ { width: '100%' } }
                            placeholder="Pilih Tahun" />
                    </Form.Item>
                </div>
                <div className="payments-flex">
                    <Form.Item
                        label="List Pembayaran"
                        name="listPembayaran"
                        style={ { width: '100%' } }
                    >
                        <Select
                            style={ { width: '100%' } }
                            placeholder="Nama Pelanggan"
                            onChange={ handleSelectList }
                            options={ yearData.map( mp => (
                                { value: mp._id, label: mp.tanggalByrLm ? mp.tanggalByrLm : mp.tanggalByrAir }
                            ) ) } />
                    </Form.Item>
                    <Form.Item
                        label="Kubik Air"
                        name="jumlahKubik"
                        style={ { width: '100%' } }
                    >
                        <InputNumber
                            style={ { width: '100%' } }
                            addonAfter="Kubik"
                            placeholder="Masukkan Jumlah"
                        />
                    </Form.Item>
                </div>
                <div className="payments-flex">
                    <Form.Item
                        label="Bayar Air"
                        name="jumlahByrAir"
                        style={ { width: '100%' } }
                    >
                        <InputNumber
                            style={ { width: '100%' } }
                            addonBefore="Rp"
                            placeholder="Masukkan Jumlah"
                        />
                    </Form.Item>
                    <Form.Item
                        label="Bayar Lampu"
                        name="jumlahByrLm"
                        style={ { width: '100%' } }
                    >
                        <InputNumber
                            style={ { width: '100%' } }
                            addonBefore="Rp"
                            placeholder="Masukkan Jumlah"
                        />
                    </Form.Item>
                </div>
                <div className="payments-flex">
                    <Form.Item
                        label="Metode Lampu"
                        name="metodeByrLm"
                        style={ { width: '100%' } }
                    >
                        <Select
                            style={ { width: '100%' } }
                            placeholder="Pilih Opsi"
                            options={ [
                                { value: 'TUNAI', label: 'TUNAI' },
                                { value: 'BRI', label: 'BRI' },
                                { value: 'BNI', label: 'BNI' },
                            ] }
                        />
                    </Form.Item>
                    <Form.Item
                        label="Metode Air"
                        name="metodeByrAir"
                        style={ { width: '100%' } }
                    >
                        <Select
                            style={ { width: '100%' } }
                            placeholder="Pilih Opsi"
                            options={ [
                                { value: 'TUNAI', label: 'TUNAI' },
                                { value: 'BRI', label: 'BRI' },
                                { value: 'BNI', label: 'BNI' },
                            ] }
                        />
                    </Form.Item>
                </div>
                <div className="payments-flex">
                    <Form.Item
                        label="Tanggal Air"
                        name="tanggalByrAir"
                        style={ { width: '100%' } }
                    >
                        <DatePicker
                            style={ { width: '100%' } }
                            placeholder="Pilih Tanggal"
                            format="DD/MM/YYYY"
                        />
                    </Form.Item>
                    <Form.Item
                        label="Tanggal Lampu"
                        name="tanggalByrLm"
                        style={ { width: '100%' } }
                    >
                        <DatePicker
                            style={ { width: '100%' } }
                            placeholder="Pilih Tanggal"
                            format="DD/MM/YYYY"
                        />
                    </Form.Item>
                </div>
                <div style={ { display: 'flex', gap: 5 } }>
                    <Button htmlType="submit" type="primary">Bayar</Button>
                    {
                        paymentId !== null && (
                            <Button onClick={ handleDelete } danger>Hapus</Button>
                        )
                    }
                </div>
            </Form>
        </Spin>
    );
};

export default EditPaymentWaterAndLamp;
