import React, { useState } from 'react';
import CreateAxiosInstance from '../../Authentication/contexts/axiosConfig';
import { Button, Form, Input, InputNumber, message, Spin } from 'antd';
import { GoCopy } from "react-icons/go";

const AccessPin = () =>
{
    const apiUrl = process.env.REACT_APP_LOCAL_API;
    const axiosConfig = CreateAxiosInstance();
    const [ form ] = Form.useForm();
    const [ pinAccess, setPinAccess ] = useState( null );
    const [ loading, setLoading ] = useState( false );

    const accessPin = async () =>
    {
        setLoading( true );
        try
        {
            const response = await axiosConfig.post( `${ apiUrl }/api/access-pin/generate` );
            setPinAccess( response.data.pin );
            form.setFieldsValue( response.data )
            message.success( 'Access PIN generated successfully' );
        } catch ( error )
        {
            console.error( error )
        } finally
        {
            setLoading( false );
        }
    };

    const handleCopyPin = () =>
    {
        if ( pinAccess )
        {
            navigator.clipboard.writeText( pinAccess.toString() );
            message.success( 'PIN copied to clipboard' );
        } else
        {
            message.error( 'No PIN to copy' );
        }
    };

    return (
        <Spin
            spinning={ loading }>
            <Form
                form={ form }
                layout='vertical'
                onFinish={ accessPin }
                style={ {
                    margin: '10px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 5
                } }>
                <div>
                    <div
                        style={ {
                            display: 'flex',
                            gap: 5,
                            alignItems: 'center'
                        } }>
                        <Form.Item
                            label='Pin Akses'
                            name='pin'
                            style={ { width: '100%' } }
                        >
                            <InputNumber readOnly style={ { width: '100%' } } />
                        </Form.Item>
                        <Form.Item
                            label=' '
                            style={ { width: '9%' } }
                        >
                            <div
                                style={ {
                                    display: 'flex',
                                    gap: 5,
                                    alignItems: 'center'
                                } }>
                                <Button
                                    onClick={ handleCopyPin }
                                    disabled={ !pinAccess }
                                    style={ { width: '100%' } }
                                    icon={ <GoCopy /> } />

                            </div>
                        </Form.Item>
                    </div>
                    <div
                        style={ {
                            display: 'flex',
                            gap: 5,
                            alignItems: 'center'
                        } }>
                        <Form.Item
                            label='Masa Aktif'
                            name='expiresAt'
                            style={ { width: '100%' } }
                        >
                            <Input readOnly style={ { width: '100%' } } />
                        </Form.Item>
                        <Form.Item
                            label=' '
                            style={ { width: '9%' } }
                        >
                            <Button
                                style={ { width: '100%' } }
                                type='primary'
                                htmlType='submit'
                                loading={ loading }>Generate</Button>
                        </Form.Item>
                    </div>
                </div>
            </Form>
        </Spin>
    );
};

export default AccessPin;
