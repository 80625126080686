import React, { useEffect, useState } from 'react';
import { Button, Drawer, Table, message, InputNumber, DatePicker, Select } from 'antd';
import { CiEdit } from 'react-icons/ci';
import PayEmployeeSalary from './bayarGajiKaryawan';
import EditEmployeeSalary from './editGajiKaryawan';
import dayjs from 'dayjs';
import { SlRefresh } from 'react-icons/sl';
import { PiCursorClickThin } from 'react-icons/pi';
import NewEmployee from './tambahKaryawan';
import CreateAxiosInstance from '../../../Authentication/contexts/axiosConfig';

const EmployeeSalaryData = () =>
{
    const [ defaultData, setDefaultData ] = useState( [] );
    const axiosConfig = CreateAxiosInstance();
    const [ loading, setLoading ] = useState( false );
    const [ drawerEditGaji, setDrawerEditGaji ] = useState( false );
    const [ drawerNew, setDrawerNew ] = useState( false );
    const [ selectedAction, setSelectedAction ] = useState();
    const [ drawerBayarGaji, setDrawerBayarGaji ] = useState( false );
    const [ selectedYear, setSelectedYear ] = useState( dayjs() );
    const [ totalYearSalary, setTotalYearSalary ] = useState();
    const [ salaryId, setSalaryId ] = useState( null );
    const [ employeeId, setEmployeeId ] = useState( null );
    const [ modifData, setModifData ] = useState( [] );

    const fetchData = async () =>
    {
        setLoading( true );
        try
        {
            const response = await axiosConfig.get( `${ process.env.REACT_APP_LOCAL_API }/employee/list` );
            setDefaultData( response.data );
        } catch ( error )
        {
            message.error( 'Gagal mengambil data dari server' );
        }
        setLoading( false );
    };

    const convertToIDR = ( value ) =>
    {
        return new Intl.NumberFormat( 'id-ID', {
            style: 'currency',
            currency: 'IDR',
            maximumFractionDigits: 0,
        } ).format( value );
    };

    useEffect( () =>
    {
        fetchData();
    }, [] );

    const handleYearChange = ( date ) =>
    {
        setSelectedYear( date );
    };

    useEffect( () =>
    {
        // FlatMap untuk memastikan tiap tanggal gaji ditampilkan sebagai baris terpisah
        const tableData = defaultData.flatMap( ( db ) =>
        {
            const salaryData = db.dataSalary.filter( ( f ) =>
                dayjs( f.dateSalary, 'DD/MM/YYYY' ).isSame( selectedYear, 'year' ) && f.dateSalary
            );

            // Jika tidak ada data gaji di tahun yang dipilih, lewati
            if ( salaryData.length === 0 ) return [];

            return salaryData.map( ( salaryItem ) => ( {
                employeeID: db._id,
                salaryID: salaryItem._id,
                employeeName: db.employeeName,
                salary: salaryItem.salary,
                additionalSalary: salaryItem.additionalSalary || 0,
                totalSalary: salaryItem.salary + ( salaryItem.additionalSalary || 0 ),
                dateSalary: salaryItem.dateSalary,
                salaryMethod: salaryItem.salaryMethod,
            } ) );
        } );

        // Menghitung total gaji per tahun
        const yearSalary = tableData.reduce( ( total, item ) => total + item.totalSalary, 0 );
        setModifData( tableData );
        setTotalYearSalary( yearSalary );
    }, [ defaultData, selectedYear ] );

    const columns = [
        {
            title: 'Aksi',
            key: 'action',
            width: '7%',
            align: 'center',
            render: ( text, record ) => (
                <Button
                    type="default"
                    onClick={ () => handleEditGaji( record.employeeID, record.salaryID ) }
                    icon={ <CiEdit /> }
                />
            ),
        },
        {
            title: <p style={ { textAlign: 'center' } }>Nama Karyawan</p>,
            dataIndex: 'employeeName',
            align: 'left',
            width: '20vw',
        },
        {
            title: 'Jumlah Gaji',
            dataIndex: 'salary',
            align: 'center',
            render: ( value ) => convertToIDR( value ),
        },
        {
            title: 'Tambahan Gaji',
            dataIndex: 'additionalSalary',
            align: 'center',
            render: ( value ) => convertToIDR( value ),
        },
        {
            title: 'Total Gaji',
            dataIndex: 'totalSalary',
            align: 'center',
            render: ( value ) => convertToIDR( value ),
        },
        {
            title: 'Tanggal Gaji',
            dataIndex: 'dateSalary',
            align: 'center',
            sorter: ( a, b ) => dayjs( a.dateSalary, 'DD/MM/YYYY' ) - dayjs( b.dateSalary, 'DD/MM/YYYY' ),
        },
        {
            title: 'Metode Gaji',
            dataIndex: 'salaryMethod',
            align: 'center',
        },
    ];

    const handleDrawerNew = () =>
    {
        setDrawerNew( true );
    };

    const handleCloseDrawerNew = () =>
    {
        setDrawerNew( false );
    };

    const handleCloseBayarGaji = () =>
    {
        setDrawerBayarGaji( false );
    };

    const handleOpenBayarGaji = () =>
    {
        setDrawerBayarGaji( true );
    };

    const handleEditGaji = ( employeeID, salaryID ) =>
    {
        setEmployeeId( employeeID );
        setSalaryId( salaryID );
        setDrawerEditGaji( true );
    };

    const handleCloseEditGaji = () =>
    {
        setEmployeeId( null );
        setSalaryId( null );
        setDrawerEditGaji( false );
    };

    const handleRefresh = () =>
    {
        fetchData();
    };

    const handleSelectChange = ( value ) =>
    {
        if ( value )
        {
            setSelectedAction( value );
        } else
        {
            setSelectedAction( null );
        }
    };

    const handleSelectAction = () =>
    {
        if ( selectedAction === 'bayarGaji' )
        {
            handleOpenBayarGaji();
        }
        if ( selectedAction === 'tambahKaryawan' )
        {
            handleDrawerNew();
        }
    };

    return (
        <div>
            <div style={ { padding: '0 5px' } }>
                <div style={ { display: 'flex', gap: 5, padding: '10px 0' } }>
                    <Button style={ { padding: 5 } } onClick={ handleRefresh } icon={ <SlRefresh /> } />
                    <Button
                        style={ { padding: 5 } }
                        type="primary"
                        onClick={ handleSelectAction }
                        icon={ <PiCursorClickThin /> }
                    />
                    <Select
                        style={ { width: '30%' } }
                        placeholder="Pilih Aksi"
                        onChange={ handleSelectChange }
                        allowClear
                        options={ [
                            { value: 'bayarGaji', label: 'Bayar Gaji' },
                            { value: 'tambahKaryawan', label: 'Tambah Karyawan' },
                        ] }
                    />
                    <InputNumber
                        readOnly
                        style={ { width: '100%' } }
                        addonBefore={ totalYearSalary >= 1 && `Gaji Tahun ${ dayjs( selectedYear ).format( 'YYYY' ) }` }
                        value={ totalYearSalary >= 1 && convertToIDR( totalYearSalary ) }
                        placeholder={ totalYearSalary < 1 && 'Tidak ada pengeluaran gaji' }
                    />
                    <DatePicker.YearPicker
                        style={ { width: '30%' } }
                        onChange={ handleYearChange }
                        defaultValue={ selectedYear }
                        allowClear={ false }
                        picker="year"
                        placeholder="Pilih Tahun"
                    />
                </div>
                <Table
                    bordered
                    columns={ columns }
                    showSorterTooltip={ false }
                    dataSource={ modifData }
                    rowKey="_id"
                    loading={ loading }
                    size="small"
                    scroll={ { y: 400 } }
                />
            </div>
            <div>
                <Drawer
                    title="Edit Gaji"
                    placement="right"
                    closable={ true }
                    onClose={ handleCloseEditGaji }
                    visible={ drawerEditGaji }
                    width="40%"
                >
                    <EditEmployeeSalary
                        salaryId={ salaryId }
                        employeeId={ employeeId }
                        onClose={ handleCloseEditGaji }
                        fetchData={ fetchData }
                    />
                </Drawer>
                <Drawer
                    title="Bayar Gaji Karyawan"
                    placement="right"
                    closable={ true }
                    onClose={ handleCloseBayarGaji }
                    visible={ drawerBayarGaji }
                    width="30%"
                >
                    <PayEmployeeSalary onClose={ handleCloseBayarGaji } fetchData={ fetchData } />
                </Drawer>
                <Drawer
                    title="Tambah Karyawan"
                    placement="right"
                    closable={ true }
                    onClose={ handleCloseDrawerNew }
                    visible={ drawerNew }
                    width="30%"
                >
                    <NewEmployee onClose={ handleCloseDrawerNew } fetchData={ fetchData } />
                </Drawer>
            </div>
        </div>
    );
};

export default EmployeeSalaryData;
