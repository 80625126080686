import React, { useEffect, useState } from 'react';
import { Table, message, DatePicker, Button, InputNumber } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import CreateAxiosInstance from '../../../Authentication/contexts/axiosConfig';
const Pemasukan = () =>
{
    const axiosConfig = CreateAxiosInstance();
    const [ data, setData ] = useState( [] );
    const [ loading, setLoading ] = useState( false );
    const [ filteredData, setFilteredData ] = useState( [] );
    const [ paidTypeFilters, setPaidTypeFilters ] = useState( [] );
    const [ totalIncome, setTotalIncome ] = useState();
    const [ selectedMonth, setSelectedMonth ] = useState( dayjs() );

    const fetchData = async () =>
    {
        setLoading( true );
        try
        {
            const responses = await axios.all( [
                axiosConfig.get( `${ process.env.REACT_APP_LOCAL_API }/customer/data` ),
                axiosConfig.get( `${ process.env.REACT_APP_LOCAL_API }/additionalIncome/data` ),
            ] );
            const [ customer, pemasukanData ] = responses.map( response => response.data );
            const customerUTJ = customer.map( db0 => ( {
                infoPaid: db0.jumlahUTJ > 0 && db0.namaCustomer,
                tanggal: db0.jumlahUTJ > 0 && db0.tanggalBooking,
                jumlah: db0.jumlahUTJ > 0 && db0.jumlahUTJ,
                paidType: db0.jumlahUTJ > 0 && db0.methodByrUTJ,
                tujuan: db0.jumlahUTJ > 0 && 'Bayar UTJ',
            } ) );
            const customerData = customer.flatMap( db1 => [
                ...db1.pembayaranAngsuran.map( angs => ( {
                    infoPaid: db1.namaCustomer,
                    tanggal: angs.tanggalByrAngs,
                    jumlah: angs.jumlahByrAngs,
                    paidType: angs.methodByrAngs,
                    tujuan: 'Bayar Angsuran',
                } ) ),
                ...db1.dataBayarDenda.map( denda => ( {
                    infoPaid: db1.namaCustomer,
                    tanggal: denda.tanggalByrDenda,
                    jumlah: denda.jumlahByrDenda,
                    paidType: denda.methodByrDenda,
                    tujuan: 'Bayar Denda',
                } ) ),
                ...db1.pembayaranDP.map( DP => ( {
                    infoPaid: db1.namaCustomer,
                    tanggal: DP.tanggalByrDp,
                    jumlah: DP.jumlahByrDp,
                    paidType: DP.metodeBayarDp,
                    tujuan: 'Bayar Dp',
                } ) ),
                ...db1.pembayaranTempo.map( tempo => ( {
                    infoPaid: db1.namaCustomer,
                    tanggal: tempo.tanggalByrTempo,
                    jumlah: tempo.jumlahByrTempo,
                    paidType: tempo.metodeByrTempo,
                    tujuan: 'Cicilan Tempo',
                } ) ),
                ...db1.pembayaranAirLampu.map( air => ( {
                    infoPaid: db1.namaCustomer,
                    tanggal: air.tanggalByrAir,
                    jumlah: air.jumlahByrAir,
                    paidType: air.metodeByrAir,
                    tujuan: `Bayar Air ${ air.jumlahKubik } M³`,
                } ) ),
                ...db1.pembayaranAirLampu.map( lmp => ( {
                    infoPaid: db1.namaCustomer,
                    tanggal: lmp.tanggalByrLm,
                    jumlah: lmp.jumlahByrLm,
                    paidType: lmp.metodeByrLm,
                    tujuan: 'Bayar Lampu',
                } ) ),
            ] );
            const dataPemasukan = pemasukanData.map( pd => ( {
                infoPaid: pd.ofWhom,
                tanggal: pd.dateGet,
                jumlah: pd.howMuch,
                paidType: pd.incomeMethod,
                tujuan: 'Pemasukan Kantor',
            } ) );
            const combinedData = [
                ...customerUTJ,
                ...customerData,
                ...dataPemasukan,
            ];
            setData( combinedData );
            setPaidTypeFilters( getUniquePaidTypes( combinedData ) );
        } catch ( error )
        {
            message.error( 'Gagal mengambil data dari server' );
        }
        setLoading( false );
    };
    const getUniquePaidTypes = ( data ) =>
    {
        const uniqueTypes = [ ...new Set( data.map( item => item.paidType ) ) ];
        return uniqueTypes.map( type => ( { text: type, value: type } ) );
    };
    const convertToIDR = ( value ) =>
    {
        return new Intl.NumberFormat( 'id-ID', {
            style: 'currency',
            currency: 'IDR',
        } ).format( value );
    };
    useEffect( () =>
    {
        fetchData();
    }, [] );
    const handleRefresh = () =>
    {
        fetchData();
    };
    const handleMonthChange = ( date ) =>
    {
        setSelectedMonth( date );
    }
    useEffect( () =>
    {
        if ( selectedMonth )
        {
            const filterData = data.filter( item => dayjs( item.tanggal, 'DD/MM/YYYY' ).isSame( selectedMonth, 'month' ) );
            const incomeData = filterData.reduce( ( total, item ) => total + item.jumlah, 0 );
            setFilteredData( filterData );
            setTotalIncome( incomeData );
        }
    }, [ selectedMonth, data ] )
    const columns = [
        {
            title: 'No',
            key: 'no',
            width: '5%',
            align: 'center',
            render: ( text, record, index ) => index + 1,
        },
        {
            title: <p style={ { textAlign: 'center' } }>Dari</p>,
            dataIndex: 'infoPaid',
            align: 'left',
            width: '25vw',
        },
        {
            title: 'Tanggal Bayar',
            dataIndex: 'tanggal',
            align: 'center',
            width: '10vw',
            sorter: ( a, b ) => dayjs( a.tanggal, 'DD/MM/YYYY' ).diff( dayjs( b.tanggal, 'DD/MM/YYYY' ) ),
            sortDirections: [ 'descend', 'ascend' ],
        },
        {
            title: 'Harga',
            dataIndex: 'jumlah',
            width: 'auto',
            align: 'center',
            render: value => convertToIDR( value ),
            sorter: ( a, b ) => a.jumlah - b.jumlah,
        },
        {
            title: 'Tujuan',
            dataIndex: 'tujuan',
            width: 'auto',
            align: 'center',
        },
        {
            title: 'Metode Bayar',
            dataIndex: 'paidType',
            width: 'auto',
            align: 'center',
            filters: paidTypeFilters,
            onFilter: ( value, record ) => record.paidType.includes( value ),
        },
    ];

    return (
        <div >
            <div style={ { padding: '0 5px' } }>
                <div style={ { display: 'flex', padding: '10px 0', gap: 5, alignItems: 'center' } }>
                    <Button type="default" onClick={ handleRefresh } >
                        Refresh
                    </Button>
                    <DatePicker.MonthPicker
                        placeholder='Tanggal'
                        onChange={ handleMonthChange }
                        format={ ( value ) => dayjs( value ).format( 'MMMM YYYY' ) }
                        picker='month'
                        allowClear={ false }
                        value={ selectedMonth }
                    />
                    <InputNumber
                        style={ {
                            display: 'flex',
                            width: '100%'
                        } }
                        readOnly
                        placeholder={ totalIncome < 1 && 'Tidak ada pengeluaran' }
                        addonBefore={ totalIncome >= 1 && 'Total' }
                        value={ totalIncome >= 1 && convertToIDR( totalIncome ) } />
                </div>
                <Table
                    bordered
                    showSorterTooltip={ false }
                    columns={ columns }
                    dataSource={ filteredData }
                    rowKey={ ( record, index ) => index }
                    loading={ loading }
                    size='small'
                    scroll={ { y: 450 } }
                />
            </div>
        </div>
    );
};

export default Pemasukan;
