import React, { useState, useEffect } from 'react';
import { Form, Button, message, Spin, Select, InputNumber, DatePicker } from 'antd';
import dayjs from 'dayjs';
import CreateAxiosInstance from '../../../../Authentication/contexts/axiosConfig';

const { Option } = Select;

const NewLoanPayment = ( { onClose, fetchData } ) =>
{
    const axiosConfig = CreateAxiosInstance();
    const [ form ] = Form.useForm();
    const [ loading, setLoading ] = useState( false );
    const [ data, setData ] = useState( [] );
    const [ clientData, setClientData ] = useState( null );

    useEffect( () =>
    {
        const fetchListData = async () =>
        {
            setLoading( true );
            try
            {
                const response = await axiosConfig.get( `${ process.env.REACT_APP_LOCAL_API }/loan/list` );
                const data = response.data;
                const updateData = data.filter( f =>
                    f.jumlahPinjaman > ( f.riwayatPembayaran.reduce( ( total, item ) => total + item.paymentValue, 0 ) )
                )
                setData( updateData );
            } catch ( error )
            {
                message.error( 'Gagal mengambil data dari server' );
                onClose();
            } finally
            {
                setLoading( false );
            }
        };
        fetchListData();
    }, [ onClose ] );

    const handleSelectClient = ( value ) =>
    {
        const dataNasabah = data.find( item => item._id === value );
        setClientData( dataNasabah );
    };

    const onFinish = async ( values ) =>
    {
        if ( !clientData )
        {
            message.error( 'Pilih nasabah terlebih dahulu' );
            return;
        }

        const pembayaranBaru = {
            ...values,
            paymentValue: values.paymentValue,
            paymentDate: dayjs( values.paymentDate ).format( 'DD/MM/YYYY' ),
            paymentMethod: values.paymentMethod,
        };

        try
        {
            setLoading( true );
            await axiosConfig.post( `${ process.env.REACT_APP_LOCAL_API }/loan/list/${ clientData._id }/data`, pembayaranBaru );
            message.success( 'Pinjaman baru berhasil ditambahkan' );
            form.resetFields();
            fetchData();
            onClose();
        } catch ( error )
        {
            message.error( 'Pinjaman baru gagal ditambahkan' );
        } finally
        {
            setLoading( false );
        }
    };

    return (
        <Spin spinning={ loading }>
            <Form form={ form } onFinish={ onFinish } layout="vertical">
                <div className="payments-flex">
                    <Form.Item
                        style={ { width: '100%' } }
                        label="Nama Nasabah"
                        name="namaNasabah"
                        rules={ [ { required: true, message: 'Pilihan diperlukan!' } ] }
                    >
                        <Select
                            placeholder="Pilih Nasabah"
                            style={ { width: '100%' } }
                            allowClear
                            showSearch
                            onChange={ handleSelectClient }
                        >
                            { data.map( nama => (
                                <Option key={ nama._id } value={ nama._id }>
                                    { nama.namaNasabah }
                                </Option>
                            ) ) }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name='paymentDate'
                        label='Tanggal Pembayaran'
                        rules={ [ { required: true, message: 'Tanggal pembayaran diperlukan!' } ] }
                    >
                        <DatePicker
                            style={ { width: '100%' } }
                            placeholder='Pilih Tanggal Pembayaran'
                            format='DD/MM/YYYY' />
                    </Form.Item>
                </div>
                <div className="payments-flex">
                    <Form.Item
                        style={ { width: '100%' } }
                        name='paymentValue'
                        label='Jumlah Pembayaran'
                        rules={ [ { required: true, message: 'Jumlah pembayaran diperlukan!' } ] }
                    >
                        <InputNumber
                            style={ { width: '100%' } }
                            addonBefore='Rp'
                            min={ 1 }
                            placeholder='Masukkan Jumlah Pembayaran' />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name='paymentMethod'
                        label='Metode Pembayaran'
                        rules={ [ { required: true, message: 'Metode pembayaran diperlukan!' } ] }
                    >
                        <Select
                            style={ { width: '100%' } }
                            placeholder='Pilih Metode Pembayaran'
                            options={ [
                                { value: 'TUNAI', label: 'TUNAI' },
                                { value: 'BRI', label: 'BRI' },
                                { value: 'BNI', label: 'BNI' },
                            ] }
                        />
                    </Form.Item>
                </div>
                <Button type="primary" htmlType="submit">
                    Bayar Lahan
                </Button>
            </Form>
        </Spin>
    );
};

export default NewLoanPayment;
