import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import LOGO from '../../Function/LOGO.png';
import './webpay.css';
import dayjs from 'dayjs';
import 'dayjs/locale/id';
import { Button, Modal, Result, Select, Table, message } from 'antd';
import CreateAxiosInstance from '../../../Authentication/contexts/axiosConfig';

dayjs.locale( 'id' );

const PaymentWeb = () =>
{
    const apiUrl = process.env.REACT_APP_LOCAL_API;
    const axiosConfig = CreateAxiosInstance();
    const { code } = useParams();
    const [ billingData, setBillingData ] = useState( [] );
    const [ customerBilling, setCustomerBilling ] = useState( [] );
    const [ action, setAction ] = useState( null );
    const [ payment, setPayment ] = useState( {} );
    const [ loading, setLoading ] = useState( true );
    const [ denda, setDenda ] = useState( 0 );
    const [ remaining, setRemaining ] = useState( 0 );
    const navigate = useNavigate();
    useEffect( () =>
    {
        navigate( '/underdevelopment-page' );
    }, [] );
    useEffect( () =>
    {
        const fetchBillingData = async () =>
        {
            try
            {
                const [ billing, customer ] = await Promise.all( [
                    axiosConfig.get( `${ apiUrl }/payment/${ code }` ),
                    axiosConfig.get( `${ apiUrl }/customer/data` ),
                ] );

                const data = billing.data;
                const custData = customer.data.find( item => item._id === data.customerId );

                const dataAngsuran = custData.pembayaranAngsuran.length > 0
                    ? custData.pembayaranAngsuran
                    : [];
                const sisaDenda = ( custData.dendaCustomer || 0 ) - ( data.penaltyCustomer || 0 );
                const belumDibayar = custData.batasAngsuran2
                    ? ( () =>
                    {
                        const dataAngsuranBefore = dataAngsuran.filter( f =>
                            dayjs( f.tanggalByrAngs2 || f.tanggalByrAngs, 'DD/MM/YYYY' ).isSameOrBefore( dayjs( custData.batasAngsuran2, 'DD/MM/YYYY' ), 'month' )
                            && dayjs( f.tanggalByrAngs2 || f.tanggalByrAngs, 'DD/MM/YYYY' ).isSameOrBefore( dayjs( data.dueDate, 'DD/MM/YYYY' ), 'month' )
                            && f.jumlahByrAngs < custData.angsuranKavling
                        ).map( d => ( {
                            belumTerbayar: custData.angsuranKavling - d.jumlahByrAngs,
                        } ) );

                        const dataAngsuranAfter = dataAngsuran.filter( f =>
                            dayjs( f.tanggalByrAngs2 || f.tanggalByrAngs, 'DD/MM/YYYY' ).isAfter( dayjs( custData.batasAngsuran2, 'DD/MM/YYYY' ), 'month' )
                            && dayjs( f.tanggalByrAngs2 || f.tanggalByrAngs, 'DD/MM/YYYY' ).isSameOrBefore( dayjs( data.dueDate, 'DD/MM/YYYY' ), 'month' )
                            && f.jumlahByrAngs < custData.angsuranKavling2
                        ).map( d => ( {
                            belumTerbayar: custData.angsuranKavling2 - d.jumlahByrAngs,
                        } ) );

                        const paymentData = [ ...dataAngsuranBefore, ...dataAngsuranAfter ];
                        const totalSisa = paymentData.reduce( ( total, item ) => total + item.belumTerbayar, 0 );

                        return { totalSisa };
                    } )()
                    : ( () =>
                    {
                        const dataAngsuranNoFilter = dataAngsuran.filter( f =>
                            f.jumlahByrAngs < custData.angsuranKavling
                        ).map( d => ( {
                            belumTerbayar: custData.angsuranKavling - d.jumlahByrAngs,
                        } ) );
                        const totalSisa = dataAngsuranNoFilter.reduce( ( total, item ) => total + item.belumTerbayar, 0 );
                        return { totalSisa };
                    } )();

                const sisaPembayaran = ( belumDibayar.totalSisa || 0 );
                const custPayment = dataAngsuran.find( item => item.angsKe === data.paymentTo );

                const jumlahBayar = custPayment
                    ? custPayment.jumlahByrAngs
                    : 0;

                const selisihBayar = data.amount - jumlahBayar;
                const formattedData = [
                    {
                        tagihan: jumlahBayar > 0 && jumlahBayar < data.amount
                            ? `Sisa Angsuran Bulan ${ dayjs( data.dueDate, 'DD/MM/YYYY' ).format( 'MMMM YYYY' ) }`
                            : jumlahBayar === 0
                                ? `Angsuran Bulan ${ dayjs( data.dueDate, 'DD/MM/YYYY' ).format( 'MMMM YYYY' ) }`
                                : 'Angsuran Bulan Ini Telah Dibayar',

                        jumlah: jumlahBayar > 0 && jumlahBayar < data.amount
                            ? convertToIDR( selisihBayar )
                            : jumlahBayar === 0
                                ? convertToIDR( data.amount )
                                : 'LUNAS',
                    },
                ];

                if ( data.underPayment > 0 )
                {
                    formattedData.push( {
                        tagihan: 'Total Sisa Angsuran Bulan Lalu',
                        jumlah: convertToIDR( data.underPayment ),
                    } );
                }

                if ( data.penaltyCustomer > 0 )
                {
                    formattedData.push( {
                        tagihan: 'Denda Keterlambatan Pembayaran',
                        jumlah: convertToIDR( data.penaltyCustomer ),
                    } );
                }
                setRemaining( sisaPembayaran );
                setDenda( sisaDenda );
                setPayment( custPayment );
                setCustomerBilling( data );
                setBillingData( formattedData );
            } catch ( error )
            {
                console.error( 'Gagal memuat data pembayaran', error );
            } finally
            {
                setLoading( false );
            }
        };

        fetchBillingData();
    }, [ code, apiUrl ] );

    const billingColumns = [
        {
            title: 'No',
            align: 'center',
            key: 'no',
            width: '8%',
            render: ( _, __, index ) => index + 1,
        },
        {
            title: <p style={ { textAlign: 'center' } }>Tagihan</p>,
            dataIndex: 'tagihan',
            key: 'tagihan',
            align: 'left',
        },
        {
            title: 'Jumlah',
            dataIndex: 'jumlah',
            key: 'jumlah',
            align: 'center',
        },
    ];

    const convertToIDR = ( value ) =>
    {
        return new Intl.NumberFormat( 'id-ID', {
            style: 'currency',
            currency: 'IDR',
            maximumFractionDigits: 0,
        } ).format( value );
    };

    const handleSelectOption = ( value ) =>
    {
        setAction( value );
    };

    const handlePaymentTransfer = () =>
    {
        if ( !action )
        {
            message.info( 'Pilih Opsi!' );
            return;
        }

        Modal.info( {
            title: 'Rekening Pembayaran',
            content: (
                <div>
                    <p className='billing-payment-value'>
                        Jumlah Pembayaran { convertToIDR(
                            action === 'bulanini'
                                ? customerBilling.amount
                                : customerBilling.amount + customerBilling.underPayment
                        ) }
                    </p>
                    <Table
                        size='small'
                        pagination={ false }
                        bordered
                        style={ { width: 'fit-content' } }
                        dataSource={ [
                            { nama: 'Syahendra Rahmadany', bank: 'BRI', norek: '318001031953534' },
                            { nama: 'Syahendra Rahmadany', bank: 'BNI', norek: '0722829890' },
                            { nama: 'Syahendra Rahmadany', bank: 'MANDIRI', norek: '1780004059602' },
                        ] }
                        columns={ [
                            { title: <p style={ { textAlign: 'center' } }>Nama</p>, dataIndex: 'nama', align: 'left', width: 'auto' },
                            { title: 'BANK', dataIndex: 'bank', align: 'center', width: 'auto' },
                            { title: 'Nomor Rekening', dataIndex: 'norek', align: 'center', width: 'auto' },
                        ] }
                    />
                </div>
            ),
            onOk ()
            {
                Modal.info( {
                    title: 'Konfirmasi Pembayaran',
                    content: (
                        <p>
                            Jika Anda telah melakukan pembayaran transfer, mohon tunggu paling lama 1 x 24 jam
                            untuk perubahan status pada tagihan online.
                        </p>
                    ),
                    onOk ()
                    {
                        Modal.confirm( {
                            title: 'Konfirmasi Bukti Transfer',
                            content: 'Apakah Anda sudah mengirim bukti transfer?',
                            okText: 'Kirim Bukti Transfer',
                            cancelText: 'Belum',
                            onOk ()
                            {
                                const phoneNumber = '6281214516622';
                                const whatsappLink = `https://wa.me/${ phoneNumber }`;
                                window.open( whatsappLink, '_blank' );
                            },
                            onCancel ()
                            {
                                message.info( 'Anda memilih belum mengirim bukti transfer.' );
                            },
                        } );
                    }
                } );
            }
        } );
    };


    if ( ( payment.jumlahByrAngs || 0 ) === customerBilling.amount && denda === 0 && remaining === 0 )
    {
        return (
            <Result
                status="success"
                title="Angsuran Telah Dibayar"
                subTitle={ `Anda telah melunasi angsuran bulan ${ dayjs( customerBilling.dueDate, 'DD/MM/YYYY' ).format( 'MMMM YYYY' ) }` }
                extra={ [
                    <Button key="close" type='default' onClick={ () => window.close() }>Tutup</Button>
                ] }
            />
        );
    }

    return (
        <section className='billing-section'>
            <div className='billing-box'>
                <img className='billing-img' src={ LOGO } alt='Logo' />
                <p className='billing-title'>Tagihan Bulanan</p>
                <Table
                    className='billing-table'
                    columns={ billingColumns }
                    dataSource={ billingData }
                    size='small'
                    bordered
                    pagination={ false }
                    loading={ loading }
                />
            </div>
            <div className='billing-action'>
                <Select
                    onChange={ handleSelectOption }
                    placeholder='Pilih Opsi'
                    className='billing-select'
                    options={ [
                        { value: 'bulanini', label: 'Bayar Bulan Ini' },
                        { value: 'lunasi', label: 'Lunasi Seluruh Tagihan' },
                    ] }
                />
                <Button
                    type='primary'
                    onClick={ handlePaymentTransfer }
                    disabled={ !action }
                >
                    Bayar
                </Button>
            </div>
        </section>
    );
};

export default PaymentWeb;
